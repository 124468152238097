import userService from "@/services/user.service";

export default function Auth(router) {
    router.beforeEach((to, from, next) => {
        let token = localStorage.getItem('user');
        if ((!token) && to.meta.requireAuth) {
            router.push({ name: 'login' });
            return;
        } else {
            userService.getUserInfo().then(({ data }) => {
                console.log(data)
                if (data.status != 1 && to.name !== 'pending') {
                    console.log("pending");
                    router.push({ name: 'pending' });
                    return;
                }
                next();
            }).catch(error => {
                console.error("Error while fetching user info:", error);
                next(error);
            });
            next();
        }
    });
}