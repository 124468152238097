<template>
    <div class="p-4">
        <form @submit="handleRegister()" class="d-flex flex-column justify-content-between h-100">
            <h1 class="title mb-3">Create New Pin</h1>

            <div class="notes mb-2">
                <div class="d-flex align-items-center justify-content-around mb-3">
                    <div class="col-1">
                        <font-awesome-icon class="text-warning" icon="fa-solid fa-triangle-exclamation" size="lg" />
                    </div>
                    <div class="col-10">
                        <span class="subtitle">PINသည် Login နှင့် ငွေထုတ်ပြုလုပ်ရာတွင်
                            အသုံးပြုရန်လိုအပ်သည်</span>
                    </div>
                </div>
                <div class="d-flex align-items-center justify-content-around mb-3">
                    <div class="col-1">
                        <font-awesome-icon class="text-warning" icon="fa-solid fa-triangle-exclamation" size="lg" />
                    </div>
                    <div class="col-10">
                        <span class="subtitle">သင့် PIN ကိုမည်သူ့ကိုမှ မမျှ၀ေပါနှင့်</span>
                    </div>
                </div>
            </div>

            <div>
                <FormGroup v-model="password" min="8" :error="errors.phone" label="New Pin" placeholder="Enter new Pin"
                    type="password">
                </FormGroup>
                <FormGroup v-model="password" :error="errors.password" label="Confirm New Pin" placeholder="Enter new Pin"
                    type="text">
                </FormGroup>
            </div>

            <PrimaryButton type="submit" class="login-btn mt-auto">Confirm</PrimaryButton>
        </form>
    </div>
</template>
<script>
export default {
    data() {
        return {
            password: "",
            phone: this.$route.params.phone,
            name: this.$route.params.name,
            errors: {

            },
        }
    },
    mounted() {
        if (this.phone == null && this.name == null) {
            this.$router.push({ name: 'register' })
        }
    },
    methods: {
        handleRegister() {
            event.preventDefault();
            this.$store.dispatch('auth/register', {
                phone: this.phone,
                name: this.name,
                password: this.password
            }).then((data) => {
                console.log(data)
                if (data.success) {
                    this.$router.push({ name: 'home' })
                }
            }).catch(err => {
                console.log(err)
            })
        }
    }
}
</script>
<style></style>
