import axios from "axios";
import authHeader from "./auth-header";

// const API_URL = 'http://192.168.1.3:8000/api/';
const API_URL = "https://aladdin.micropos.app/api/";

// const Towd_API_URL = "https://live2d.microland.cloud/api/";
const Towd_API_URL = "https://aladdin.micropos.app/api/twod/winning_number/";

class UserService {
   getUserInfo() {
      return axios.get(API_URL + "customer/info", { headers: authHeader() });
   }

   applyTopUp(data) {
      return axios.post(API_URL + "deposit", data, { headers: authHeader() });
   }

   //2D
   get2DScheduleList() {
      return axios.get(API_URL + "twod_section");
   }

   get2DHistoryList() {
      return axios.get(API_URL + "");
   }

   get2DScheduleDetail(id) {
      return axios.get(API_URL + "twod_section/" + id);
   }

   bet2DSchedule(id, data) {
      return axios.post(API_URL + "twod_section/" + id + "/bet", data, {
         headers: authHeader(),
      });
   }

   getUser2DBettingHistory() {
      return axios.get(API_URL + "twod/betting_log", { headers: authHeader() });
   }

   getUser2DBettingHistoryDetail(date) {
      return axios.get(API_URL + "twod/betting_log/" + date, {
         headers: authHeader(),
      });
   }

   getTwodLiveNumber() {
      console.log(Towd_API_URL)
      // return axios.get(Towd_API_URL + "live");
   }

   //3D
   bet3DSchedule(id, data) {
      return axios.post(API_URL + "threed_section/" + id + "/bet", data, {
         headers: authHeader(),
      });
   }

   get3DScheduleList() {
      return axios.get(API_URL + "threed_section");
   }

   get3DScheduleHistory() {
      return axios.get(API_URL + "threed_section/history");
   }

   get3DScheduleDetail(data) {
      return axios.get(API_URL + "threed_section/" + data);
   }

   getUser3DBettingHistory() {
      return axios.get(API_URL + "threed/betting_log", {
         headers: authHeader(),
      });
   }

   getUser3DBettingHistoryDetail(date) {
      return axios.get(API_URL + "threed/betting_log/" + date, {
         headers: authHeader(),
      });
   }

   getTransactionDetail(id) {
      return axios.get(API_URL + "transaction/" + id, {
         headers: authHeader(),
      });
   }

   getNotifications() {
      return axios.get(API_URL + "notification", { headers: authHeader() });
   }

   getAdminBoard() {
      return axios.get(API_URL + "admin", { headers: authHeader() });
   }

   getAgents() {
      return axios.get(API_URL + "agent-info/agent");
   }

   getClosingDays() {
      return axios.get(API_URL + "closing-day");
   }

   getHomeBanner() {
      return axios.get(API_URL + "slide/home");
   }

   getServices() {
      return axios.get(API_URL + "service");
   }

   getServiceBanner() {
      return axios.get(API_URL + "slide/service");
   }

   getWithdrawAgentList(amount) {
      return axios.get(API_URL + "agent/withdraw/" + amount);
   }

   getWithdrawAgentInfo(id) {
      return axios.get(API_URL + "agent/withdraw/" + id + "/info");
   }

   getPaymentMethods() {
      return axios.get(API_URL + "payment-method");
   }

   getDepositAgentList(amount) {
      return axios.get(API_URL + "agent/deposit/" + amount);
   }

   getDepositAgentInfo(id) {
      return axios.get(API_URL + "agent/deposit/" + id + "/info");
   }

   submitWithdraw(id, data) {
      return axios.post(API_URL + "agent/withdaw/" + id + "/confirm", data, {
         headers: authHeader(),
      });
   }

   submitDeposit(id, data) {
      return axios.post(API_URL + "agent/deposit/" + id + "/confirm", data, {
         headers: authHeader(),
      });
   }

   updateProfile(data) {
      return axios.post(API_URL + "profile/update", data, {
         headers: authHeader(),
      });
   }

   getTransactionHistory() {
      return axios.get(API_URL + "transaction", { headers: authHeader() });
   }

   getTransactionHistoryFromAgent() {
      return axios.get(API_URL + "transaction/get/from-agent", { headers: authHeader() });
   }

   getComments(page) {
      return axios.get(API_URL + "comment?page=" + page);
   }

   uploadComment(data) {
      return axios.post(API_URL + "comment/store", data, {
         headers: authHeader(),
      });
   }

   updateComment(id) {
      return axios.post(API_URL + "comment/" + id + "/update", {
         headers: authHeader(),
      });
   }

   deleteComment(id) {
      return axios.post(API_URL + "comment/" + id + "/delete", {
         headers: authHeader(),
      });
   }

   getOTP(data) {
      return axios.post(API_URL + "otp/get", data);
   }

   getheaderList() {
      return axios.get(API_URL + "header_list" );
   }

   ThreeDTopCustomer() {
      return axios.get(API_URL + "threed/top_customers")
   }
   TwoDTopCustomer(id) {
      return axios.get(API_URL + "twod/top_customers/"+ id)
   }

}

export default new UserService();
