import moment from "moment";
import store from "@/store";
export default {
    formattedNumber(num) {
        if (!num) {
            return
        }
        var str = num.toString().split('.');
        if (str[0].length >= 0) {
            str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
        }
        if (str[1] && str[1].length >= 0) {
            str[1] = str[1].replace(/(\d{3})/g, '$1 ');
        }
        return str.join('.');
    },
    formattedTime(time) {
        return moment(time, ['HH:mm']).format('hh:mm A');
    },
    formattedTimer(secs) {
        return (moment.utc(secs*1000).format('HH:mm:ss')).replace("00:","")
    },
    formattedDateTime(date_time) {
        return moment(date_time).format('D/MM/y hh:mm A');
    },
    getDayTime(time) {
        return moment(time).format('A');
    },
    copyToClipBoard(content) {
        const clipboardData =
            event.clipboardData ||
            window.clipboardData ||
            event.originalEvent?.clipboardData ||
            navigator.clipboard;
        clipboardData.writeText(content);
        store.dispatch('showToast', 'Copied to clipboard')
    },
}