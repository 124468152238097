<template>
    <div class="p-4">
        <div class="d-flex mb-3">
            <div class="w-50 text-start fw-600 fs-16">ရက်စွဲ</div>
            <div class="w-50 text-end fw-600 fs-16">2D ပေါက် ဂဏန်း</div>
        </div>
        <!-- <ul class="list-group list-group-flush">
            <router-link :to="{
                name: '3d-betting-list-detail', params: {
                    date: item.date
                }
            }" v-for="(item, index) in history" :key="index" class="list-group-item py-3 d-flex align-items-center">
                <h1 class="fw-600 fs-16 mb-0">{{ item.date }}</h1>
                <p class="ms-auto me-4 fw-400 fs-16 mb-0">{{ $helper.formattedNumber(item.total_amount) }} Ks</p>
                <font-awesome-icon icon="fa-solid fa-chevron-right" />
            </router-link>
        </ul> -->

        <div data-aos="fade-up" data-aos-duration="1500">
            <ul class="list-group list-group-flush">
                <li v-for="(section, index) in history" :key="index" class="list-group-item d-flex align-items-center ">
                    <div class=" w-100">
                        <span class="fw-600 fs-16">Date:<span class="fw-600 fs-14 ms-2 mt-2">{{ index }}</span></span>
                        <!-- <p class="fw-500 fs-14 mb-0"></p> -->
                        <div class="my-2 shadow-sm p-2 rounded-1 ">
                            <div v-for="(data, unit)  in section " :key="unit"
                                :class="`${unit % 2 === 0 ? 'bg-light' : ''} rounded-1`">


                                <div class="d-flex  justify-content-between p-1 ps-2" >
                                    <div>
                                        <p class="fw-500 fs-14 mb-0 my-2">{{ data.opening_time_format }}</p>
                                    </div>
                                    <div style="position:relative;top:15px">
                                        <span class=" ms-auto fw-500  fs-16 badge text-bg-success">{{ data.winning_number
                                        }}</span>
                                    </div>
                                </div>


                                <div class="ps-2 pb-1">
                                    <h6>{{ data.type?.name ? data.type?.name : '-----' }}</h6>
                                </div>

                            </div>

                            <div>

                            </div>
                        </div>
                    </div>

                    <!-- <span class="ms-auto fw-500 fs-18 badge badge-success">{{ section.winning_number }}</span> -->
                </li>
            </ul>
        </div>
    </div>
</template>


<script>

export default {
    data() {
        return {
            history: [],
        }
    },
    mounted() {
        this.fetchHistory();
    },
    methods: {
        fetchHistory() {
            this.$store.dispatch('loading')
            const url = `https://aladdin.micropos.app/api/twod/history`;
            fetch(url)
                .then(response => response.json())
                .then(data => {
                    this.$store.dispatch('loadingComplete')
                    this.history = data;
                    console.log(data)
                })
                .catch(error => {
                    console.error(error);
                });
        },
    }
}
</script>
<style>
.winners-nav {
    background-color: #3367EE;
    text-align: center;
    color: white;
    outline: none;
    border: none;
}

.winner-nav-item {
    padding: 10px 0px;
    color: #fff;
    text-decoration: none;
}

.winner-nav-item.active {
    background-color: #3367EE;
}

/* Define keyframes for the animation */
</style>