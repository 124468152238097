<template>
    <div class="p-4">
        <div class="d-flex mb-3">
            <div class="w-50 text-start fw-600 fs-16">ရက်စွဲ</div>
            <div class="w-50 text-end fw-600 fs-16">စုစုပေါင်းထိုးငွေ</div>
        </div>
        <div data-aos="fade-up" data-aos-duration="1500">
            <ul class="list-group list-group-flush">
                <router-link :to="{
                    name: '3d-betting-list-detail', params: {
                        date: item.date
                    }
                }" v-for="(item, index) in history" :key="index"
                    :class="`${index % 2 === 0 ? 'bg-light' : ''} list-group-item py-3 d-flex align-items-center`">
                    <h1 class="fw-500 fs-14 mb-0 px-2">{{ item.date }}</h1>
                    <p class="ms-auto me-4 fw-400 fs-14 mb-0">{{ $helper.formattedNumber(item.total_amount) }} Ks</p>
                    <font-awesome-icon icon="fa-solid fa-chevron-right" />
                </router-link>
            </ul>
        </div>

    </div>
</template>
<script>
import userService from '@/services/user.service';

export default {
    data() {
        return {
            history: []
        }
    },
    mounted() {
        this.$store.dispatch('loading')
        userService.getUser3DBettingHistory().then((response) => {
            console.log(response.data);
            this.history = response.data.data
            this.$store.dispatch('loadingComplete')
        })
    }
}
</script>
<style>
.winners-nav {
    background-color: #3367EE;
    text-align: center;
    color: white;
    outline: none;
    border: none;
}

.winner-nav-item {
    padding: 10px 0px;
    color: #fff;
    text-decoration: none;
}

.winner-nav-item.active {
    background-color: #3367EE;
}
</style>