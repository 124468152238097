<template>
    <div class="p-4">
        <form @submit.prevent="next()" class="d-flex flex-column h-100">
            <div class="mb-4">
                <h1 class="fw-600 fs-18 mb-0">Ks ဝယ်မည်</h1>
                <span class="fw-400 fs-14">ဖြည့်သွင်းမည့် Ks ပမာဏရွေးချယ်ပါ</span>
            </div>

            <form-group :errors="errors.amount" label="Amount" :required="true" placeholder="Enter amount"
                v-model="selectedAmount"></form-group>

            <div class="d-flex align-items-center my-2">
                <span style="flex-grow:1;height: 1px;background-color: #EBF0FD;"></span>
                <span class="fw-400 fs-14 mx-2">or</span>
                <span style="flex-grow:1;height: 1px;background-color: #EBF0FD;"></span>
            </div>

            <h2 class="fw-600 fs-14 mb-3">Select amount</h2>
            <div class="row">
                <div @click="selectAmount(amount)" v-for="(amount, key) in  amount_options" :key="key"
                    class="col-4 mb-2 text-center">
                    <div :class="{
                        'bg-primary text-white': selectedAmount == amount,
                        'text-primary bg-amount': selectedAmount != amount
                    }" class="text-center py-2 rounded">
                        {{ $helper.formattedNumber(amount) }}
                    </div>
                </div>
            </div>

            <div class="mt-3 d-flex align-items-center">
                <font-awesome-icon icon="fa-brands fa-youtube" class="me-2 text-primary" size="lg" />
                <span class="fw-400 fs-13 text-decoration-underline">အမှားအယွင်းမရှိစေရန် Unitဝယ်နည်း ကြည့်ပါ</span>
            </div>

            <PrimaryButton type="submit" class="login-btn mt-auto">ဆက်သွားမည်</PrimaryButton>
        </form>
    </div>
</template>
<script>
export default {
    data() {
        return {
            amount_options: [
                5000, 10000, 50000, 100000, 2000000
            ],
            selectedAmount: this.$store.state.cash.selectedTopUpAmount,
            errors: {},
        };
    },
    watch: {
        selectedAmount() {
            this.$store.dispatch('cash/chooseSelectedTopUpAmount', this.selectedAmount)
        }
    },
    methods: {
        selectAmount(amount) {
            this.selectedAmount = amount;
        },
        next() {
            if (!this.selectedAmount) {
                this.errors = {
                    amount: ['Amount field is required!']
                }
                return;
            }

            if (this.selectedAmount < 5000) {
                this.errors = {
                    amount: ['Minimum amount is 5,000 Ks!']
                }
                return;
            }


            delete this.errors.amount
            this.$router.push({ name: 'top-up.choose-agent' })
        }
    },
}
</script>
<style>
.selected-payment {
    background-color: #3367EE1A;
}

.bg-amount {
    background: #EBF0FD;
}

.amount-input {
    background: #EBF0FD;
    border: 0;
    border-radius: .7rem;
    width: 100%;
    padding: .8rem 1.2rem;
    font-size: .85rem;
}
</style>
