<template>
    <div class="d-flex text-center footer-nav w-100 bg-white p-2">

        <router-link v-for="(route, index) in routes" :to="{ name: route.routeName }" :key="index"
            class="col-3 footer-nav-item">
            <div>
                <font-awesome-icon :icon="route.icon" :size="route.iconSize"></font-awesome-icon>
                <p class="footer-label">{{ route.name }}</p>
            </div>
        </router-link>

    </div>
</template>
<script>
export default {
    data() {
        return {
            routes: [
                {
                    name: "ပင်မ",
                    icon: "fa-solid fa-home",
                    iconSize: "lg",
                    routeName: "home"
                },
                // {
                //     name: "ထီပေါက်သူ",
                //     icon: "fa-solid fa-award",
                //     iconSize: "lg",
                //     routeName: "winners"
                // },
                {
                    name: "ထိုးမှတ်တမ်း",
                    icon: "fa-solid fa-award",
                    iconSize: "lg",
                    routeName: "betting-list"
                },
                {
                    name: "အကူအညီ",
                    icon: "fa-solid fa-phone",
                    iconSize: "lg",
                    routeName: "services"
                },
                {
                    name: "ကျွန်ုပ်ပရိုဖိုင်",
                    icon: "fa-solid fa-user-circle",
                    iconSize: "lg",
                    routeName: "profile"
                }
            ]
        }
    }
}
</script>
<style>
.footer-nav {
    border-top: 1px solid #00000010;
    position: fixed;
    bottom: 0;
    height: auto;
}

.footer-nav-item {
    color: #000000B2;
    text-decoration: none;
}

.footer-nav-item.router-link-active {
    color: #3367EE;
}

.footer-label {
    margin-top: 3px;
    font-size: 12px;
    margin-bottom: 0;
}
</style>