<template>
    <div class="p-4">
        <form @submit="onUpdateProfile()" class="d-flex flex-column h-100">
            <template v-if="step == 1">

                <div>
                    <div class="d-flex flex-wrap mb-4 align-items-center">
                        <div class="col-5">
                            <img v-if="previewImage" :src="previewImage" class="profile-image rounded-circle" alt="">
                            <img v-else :src="user.avatar" class="profile-image rounded-circle" alt="">

                            <input ref="chooseImage" type="file" class="d-none" @change="imageChoosed">
                        </div>
                        <div class="col-6">
                            <SecondaryButton @click="takePhoto()" type="button" class="mb-2 fw-600 fs-14">Take Photo
                            </SecondaryButton>
                            <SecondaryButton type="button" @click="chooseImage()" class="fw-600 fs-14">Upload Image
                            </SecondaryButton>
                        </div>
                        <div class="col-12">
                            <small class="text-danger fw-400 fs-12" v-for="(message, index) in errors.image" :key="index">{{
                                message }}</small>
                        </div>
                    </div>

                    <FormGroup :required="true" v-model="user.name" :errors="errors.name" label="Username"
                        placeholder="Enter your name" type="text">
                    </FormGroup>
                    <FormGroup :readOnly="true" v-model="user.phone" :errors="errors.phone" label="Phone Number"
                        placeholder="09123456789" type="number">
                    </FormGroup>

                    <div class="col-6 mx-auto">
                        <PrimaryButton type="submit">သိမ်းဆည်းမည်</PrimaryButton>
                    </div>
                </div>
            </template>
        </form>

        <ModalBox :show="showCropModal" @close="showCropModal = false">
            <template v-slot:header>Crop</template>
            <template v-slot:body>
                <cropper class="cropper" ref="cropper" :src="previewImage" :stencil-props="{
                    aspectRatio: 1 / 1
                }" @change="change" />
                <div class="d-flex mt-2">
                    <div class="col-6 pe-1">
                        <SecondaryButton class="fw-700 fs-14" @click="showCropModal = false">Cancel</SecondaryButton>
                    </div>
                    <div class="col-6 ps-1">
                        <PrimaryButton class="fw-700 fs-14" @click="crop()">
                            OK</PrimaryButton>
                    </div>
                </div>
            </template>
        </ModalBox>
    </div>
</template>
<script>
import userService from '@/services/user.service';
import ModalBox from '@/components/ModalComponent.vue';

import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
export default {
    components: {
        Cropper, ModalBox
    },
    data() {
        return {
            step: 1,
            showCropModal: false,
            OTPSeconds: 60,
            OTPLabel: "Get OTP",
            previewImage: null,
            user: this.$store.state.auth.user,
            image: null,
            errors: {},
            coordinates: {
                width: 0,
                height: 0,
                left: 0,
                top: 0,
            },
        };
    },
    methods: {
        takePhoto() {
            const inputElement = this.$refs.chooseImage;

            // Clear previously selected files, if any
            inputElement.value = '';

            // Set the capture attribute to "camera" to show only the camera option
            inputElement.setAttribute("capture", "camera");

            inputElement.click();
            // Request camera permission
            navigator.permissions
                .request({ name: 'camera' })
                .then((permissionStatus) => {
                    if (permissionStatus.state === 'granted') {
                        // Permission granted, trigger the click event
                        inputElement.click();
                    } else if (permissionStatus.state === 'prompt') {
                        // Permission prompt shown, listen for changes in permission status
                        permissionStatus.onchange = () => {
                            if (permissionStatus.state === 'granted') {
                                // Permission granted, trigger the click event
                                inputElement.click();
                            } else {
                                console.error('Camera permission denied');
                            }
                        };
                    } else {
                        console.error('Camera permission denied');
                    }
                })
                .catch((error) => {
                    console.error('Could not request camera permission:', error);
                });

        },
        chooseImage() {
            this.$refs.chooseImage.click()
        },
        imageChoosed(e) {
            this.showCropModal = true
            console.log('hello')
            const image = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
                this.previewImage = e.target.result;
            };
            this.image = e.target.files[0]

        },
        crop() {
            const { canvas } = this.$refs.cropper.getResult();
            this.image = canvas
            this.previewImage = canvas.toDataURL();
            this.showCropModal = false
        },
        onUpdateProfile() {
            event.preventDefault();
            let data = new FormData();
            data.append('name', this.user.name);
            if (this.image) {
                let that = this
                that.image.toBlob(blob => {
                    data.append('image', blob);
                    that.updateProfile(data)
                }, 'image/jpeg');
            } else {
                this.updateProfile(data)
            }


        },
        updateProfile(data) {
            this.$store.dispatch('loading')
            userService.updateProfile(data).then(({ data }) => {
                this.$store.dispatch('loadingComplete')
                if (data.success) {
                    this.$store.dispatch('showToast', 'Profile Updated Successfully!')
                    this.$router.push({ name: 'home' })
                }
            }).catch(({ response }) => {
                if (response.status == 422) {
                    this.errors = response.data.errors
                }
            })
        }
    },
}
</script>

<style>
.profile-image {
    width: 100px;
    height: 100px;
    background-color: #EBF0FD;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.get-otp {
    font-weight: 800;
    position: relative;
    top: 7px;
}
</style>
