<template>
    <div>
        <div class="d-flex winners-nav border-0">
            <a @click="type = '2d'" class="w-50 winner-nav-item" :class="{
                active: type == '2d'
            }">2D ထိုးမှတ်တမ်း</a>
            <!-- <a @click="type = '3d'" class="w-50 winner-nav-item" :class="{
                active: type == '3d'
            }">2D မှတ်တမ်း</a> -->
            <router-link @click="type = '3d'" class="w-50 winner-nav-item" :class="{
                active: type == '3d'
            }" :to="{ name: 'ThreeDBettingList' }">
                3D ထိုးမှတ်တမ်း
            </router-link>
        </div>
        <template v-if="type == '3d'">
            <TwoDHistory></TwoDHistory>
        </template>
        <template v-else>
            <TwoDBettingListView></TwoDBettingListView>
        </template>
    </div>
</template>
<script>
import TwoDBettingListView from '@/views/2DBettingListView.vue';
import TwoDHistory from '@/views/2DHistory.vue';

export default {
    components: {
        TwoDBettingListView, TwoDHistory
    },
    data() {
        return {
            type: "2d"
        }
    },
    mounted() {
        if (this.$route.params.type) {
            this.type = this.$route.params.type
        }
    }
}
</script>
<style>
.winners-nav {
    background-color: #3367EE;
    text-align: center;
    color: white;
    outline: none;
    border: none;
}

.winner-nav-item {
    padding: 10px 0px;
    color: #fff;
    text-decoration: none;
}

.winner-nav-item.active {
    color: white !important;
    background-color: #2B5DDC !important;
}
</style>