<template>
    <button :type="type" class="btn font-poppins fw-600 rounded-3 fs-14 primary-btn btn-primary w-100">
        <slot></slot>
    </button>
</template>
<script>
export default {
    props: {
        type: {
            type: String,
            default: 'button'
        }
    }
}
</script>
<style>
.primary-btn {
    padding: .6rem 0 !important;
}
</style>