
<template>
    <div class="profile">
        <div class="banner" v-if="logined">
            <div class="banner-icon">
                <img v-if="this.userInfo.avatar != null" :src="this.userInfo.avatar" class="rounded-circle" width="50"
                    style="aspect-ratio: 1/1;border-width: .1rem;border-width: .3rem;" alt="">
                <img v-else src="https://aladdin.micropos.app/user.png" class="rounded-circle" width="50"
                    style="aspect-ratio: 1/1;border-width: .1rem;border-width: .3rem;" alt="">
            </div>
            <h1 class="fw-600 fs-14 text-white mt-3 mb-0">{{ this.userInfo?.name }}</h1>
            <p class="fw-500 fs-12 phone">{{ this.userInfo?.phone }}</p>
        </div>
        <div v-else class="banner">
            <div class="banner-icon">
                <font-awesome-icon icon="fa-solid fa-user" size="xl" class="text-primary"></font-awesome-icon>
            </div>
            <p class="fw-600 fs-13 text-white mt-4 mb-3">အကောင့်ဖွင့်ပါ (သို့) Log in ဝင်ပါ</p>
        </div>
        <div class="p-3">
            <div class="d-flex" v-if="!logined">
                <router-link :to="{ name: 'login' }" class="w-50">
                    <SecondaryButton class="fw-600 fs-14 me-1 w-50">Login</SecondaryButton>
                </router-link>
                <router-link :to="{ name: 'register' }" class="w-50">
                    <PrimaryButton class="fw-600 fs-14 ms-1">Register</PrimaryButton>
                </router-link>
            </div>
            <div class="px-2 py-4">
                <h1 class="fw-700 fs-16 mb-0">ဆက်တင်များ</h1>
                <div class="row align-items-center" v-if="logined">

                    <div class="col-1 d-flex justify-content-center">
                        <font-awesome-icon icon="fa-solid fa-user-circle" size="lg" class="text-primary" />
                    </div>

                    <div class="col-11 my-3">
                        <router-link :to="{ name: 'profile.view' }" class="w-100 text-dark d-flex text-decoration-none">
                            <span class="fw-600 fs-14 ">ကျွန်ုပ်အချက်အလက်</span>
                            <font-awesome-icon icon="fa-solid fa-angle-right" size="lg" class="ms-auto" />
                        </router-link>
                    </div>

                    <div class="col-1 d-flex justify-content-center">
                        <font-awesome-icon icon="fa-solid fa-user-circle" size="lg" class="text-primary" />
                    </div>

                    <div class="col-11 my-3">
                        <router-link :to="{ name: 'changepassword' }" class="w-100 text-dark d-flex text-decoration-none">
                            <span class="fw-600 fs-14 ">Change Password</span>
                            <font-awesome-icon icon="fa-solid fa-angle-right" size="lg" class="ms-auto" />
                        </router-link>
                    </div>


                    <!-- <div class="col-1 d-flex justify-content-center">
                        <font-awesome-icon icon="fa-solid fa-user-plus" size="lg" class="text-primary" />
                    </div>

                    <div class="col-11 my-3 d-flex align-items-center">
                        <router-link :to="{ name: 'home' }" class="w-100 text-dark d-flex flex-column text-decoration-none">
                            <span class="fw-600 fs-14 ">မိတ်ဆက်ကုပ်</span>
                            <span class="fw-400 fs-12 text-primary">Code from: Zay Ya</span>
                        </router-link>
                        <font-awesome-icon icon="fa-solid fa-angle-right" size="lg" class="ms-auto" />

                            </div> -->
                </div>
                <div class="row align-items-center">
                    <div class="col-1 d-flex justify-content-center">
                        <font-awesome-icon icon="fa-solid fa-file-lines" size="lg" class="text-primary" />
                    </div>

                    <div class="col-11 my-3">
                        <router-link :to="{ name: 'privacy-policy' }" class="w-100 text-dark d-flex text-decoration-none">
                            <span class="fw-600 fs-14 ">ကိုယ်ရေးအချက်အလက်မူဝါဒ</span>
                            <font-awesome-icon icon="fa-solid fa-angle-right" size="lg" class="ms-auto" />
                        </router-link>
                    </div>

                    <div class="col-1 d-flex justify-content-center">
                        <font-awesome-icon icon="fa-solid fa-star" size="lg" class="text-primary" />
                    </div>
                    <div class="col-11 my-3">
                        <router-link :to="{ name: 'home' }" class="w-100 text-dark d-flex text-decoration-none">
                            <span class="fw-600 fs-14 ">Rating ပေးမည်</span>
                        </router-link>
                    </div>

                    <div class="col-1 d-flex justify-content-center">
                        <font-awesome-icon icon="fa-solid fa-mobile" size="lg" class="text-primary" />
                    </div>
                    <div class="col-11 my-3">
                        <router-link :to="{ name: 'home' }" class="w-100 text-dark d-flex flex-column text-decoration-none">
                            <span class="fw-600 fs-14 ">ယခုဗားရှင်း</span>
                            <span class="fw-400 fs-12 text-primary">Version 1.0.0</span>
                        </router-link>
                    </div>

                    <div class="col-1 d-flex justify-content-center">
                        <font-awesome-icon icon="fa-solid fa-arrow-right-from-bracket" size="lg" class="text-primary" />
                    </div>
                    <div class="col-11 my-3">
                        <a @click="showConfirmationModal = true" class="w-100 text-dark d-flex text-decoration-none">
                            <span class="fw-600 fs-14 ">အကောင့်ထွက်မည်</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <confirmation-box @cancel="showConfirmationModal = false" @ok="logOut" :show="showConfirmationModal"
            title="အကောင့်ထွက်မည်" subtitle="အကောင့်ထွက်မှာ သေချာပါသလား" icon="fa-solid fa-arrow-right-from-bracket"
            cancelButtonText="မသေချာပါ" okButtonText="ထွက်မည်" />
    </div>
</template>
<script>
import ConfirmationBox from '@/components/ConfirmationBoxComponent.vue';
import userService from '@/services/user.service';
export default {
    components: { ConfirmationBox },
    data() {
        return {
            showConfirmationModal: false,
            logined: this.$store.state.auth.status.loggedIn,
            currentUser: this.$store.state.auth.user,
            userInfo: {}
        }
    },
    mounted() {
        this.getUserInfo();
    },
    methods: {
        getUserInfo() {
            userService.getUserInfo().then(({ data }) => {
                // console.log(data)
                this.userInfo = data;
            })
        },
        logOut() {
            localStorage.removeItem('userphone');
            localStorage.removeItem('userpassword');
            this.$store.dispatch('auth/logout');
            this.$router.push('/login');
        }
    }
}
</script>
<style scoped>
.profile .banner {
    background: url('../assets/winner-banner.svg');
    height: 21vh;
    position: relative;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0px 0px 1rem 1rem;
}

.banner .phone {
    color: #FFFFFFCC;
}

.profile .banner-icon {
    width: 15vw;
    height: 15vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 50%;
}
</style>