<template>
    <div class="apply-top-up" style="width: 100vw;">
        <div class="banner">
            <img src="@/assets/check.svg" alt="">
        </div>
        <div class="description d-flex flex-column justify-content-end text-center text-white p-4">
            <div class="text-center">
                <h1 class="title text-white">Account Create Success</h1>
                <p class="subtitle text-secondary">Aladdin မှကြိုဆိုပါသည်</p>

            </div>
            <router-link :to="{ name: 'home' }">
                <PrimaryButton class="btn-register">Get Started</PrimaryButton>
            </router-link>
        </div>
    </div>
</template>
<style scoped>
.btn-register {
    margin-top: .8rem;
}

.apply-top-up .banner {
    height: 55vh;
    width: 100vw;
    position: relative;
    background-position: center;
    background-size: cover;
    background: url('../assets/background.svg');
}

.banner img {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 30vw;
}

.apply-top-up .description {
    height: 45vh;
}

.apply-top-up {
    background-color: #0B1C30;

}
</style>
