

<template>
    <div class="profile">
        <div class="banner p-3">
            <div class="w-100 h-100 d-flex flex-column rounded-4">
                <carousel :autoplay="2000" :items-to-show="1" style="overflow: hidden;">
                    <slide v-for="slide in bannerImages" :key="slide">
                        <img :src="slide.image" style="width: 100%;aspect-ratio: 16/9;" alt="">
                    </slide>
                </carousel>
                <marquee class="text-custom bg-white mt-auto rounded-4 fw-400 fs-13 py-1" behavior="" direction="">
                    <font-awesome-icon class="me-2" icon="fa-solid fa-bullhorn"></font-awesome-icon>
                    {{ serviceBannerText }}
                </marquee>
            </div>
        </div>
        <div class="p-4">
            <h1 class="fw-700 fs-16 mb-3">Service ဖုန်းနံပါတ်များ</h1>
            <ul class="list-group list-group-flush">
                <li v-for="(help, index) in helps" :key="index" class="list-group-item d-flex align-items-center">

                    <font-awesome-icon v-if="help.type == 'viber'" icon="fa-brands fa-viber" size="lg"
                        class="me-3 text-custom" />
                    <font-awesome-icon v-else icon="fa-solid fa-phone" size="lg" class="me-3 text-custom" />

                    <div>
                        <p class="fw-400 fs-12 mb-1 text-capitalize">{{ help.name }}</p>
                        <h1 class="fw-600 fs-14 mb-0">{{ help.phone }}</h1>
                    </div>
                    <a :href="'tel:' + help.phone" class="fw-500 fs-13 ms-auto text-custom text-decoration-none">{{
                        help.contact == 'call' ? 'ဖုန်းခေါ်မည်' :
                        'စကားပြောမည်'
                    }}</a>
                </li>
            </ul>
            <div class="d-flex justify-content-center mt-4">
                <a v-if="links.telegram_link" :href="'https://t.me/'+links.telegram_link">
                    <font-awesome-icon size="xl" class="text-custom mx-2" icon="fa-brands fa-telegram" />
                </a>
                <a v-if="links.viber" :href="'tel:'+links.viber">
                    <font-awesome-icon size="xl" class="text-custom mx-2" icon="fa-brands fa-viber" />
                </a>
                <a v-if="links.facebook_link" :href="links.facebook_link">
                    <font-awesome-icon size="xl" class="text-custom mx-2" icon="fa-brands fa-facebook" />
                </a>
                <!-- <a v-if="links.youtube_link" :href="links.youtube_link">
                    <font-awesome-icon size="xl" class="text-custom mx-2" icon="fa-brands fa-youtube" />
                </a> -->
            </div>
        </div>
    </div>
</template>
<script>
import userService from '@/services/user.service';
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide } from 'vue3-carousel'

export default {
    components: {
        Carousel,
        Slide,
    },
    data() {
        return {
            helps: [],
            links: []
        }
    },
    computed: {
        serviceBannerText() {
            return this.$store.state.index.serviceBannerText
        },
        bannerImages() {
            return this.$store.state.index.serviceBannerImages;
        }
    },
    mounted() {
        userService.getServices().then(({ data }) => {
            this.helps = data.data
            this.links = data.links
            console.log(this.links)
        })
    }
}
</script>
<style scoped>
.profile .banner {
    background: url('../assets/home-banner.png');
    position: relative;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0px 0px 1rem 1rem;
}

.banner .phone {
    color: #FFFFFFCC;
}

.profile .banner-icon {
    width: 18vw;
    height: 18vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 50%;
}

.banner marquee {
    border-top-right-radius: 0px !important;
    border-top-left-radius: 0px !important;
    background-color: #447BE833;
    height: 28px;
}
</style>


{
    "facebook_link": "https://www.facebook.com",
    "telegram_link": "@AladdinThai2D",
    "youtube_link": "www.youtube.com"
}