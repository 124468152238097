<template>
    <div class="p-3">
        <table class="table">
            <thead>
                <tr class="fw-600 fs-13">
                    <th class="text-center">အချိန်</th>
                    <th class="text-center">လိုင်း</th>
                    <th class="text-center">ဆ</th>
                    <th class="text-center">နံပါတ်</th>
                    <th class="text-center">ကြေး</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in list" :key="index" class="fw-400 fs-12 text-center">
                    <td>{{ item.created_at }}</td>
                    <td>
                        {{ item.title }}
                    </td>
                    <td>{{ item.multiply }}</td>
                    <td>{{ item.bet_number }}</td>
                    <td>{{ $helper.formattedNumber(item.amount) }} Ks</td>
                </tr>
            </tbody>

        </table>
    </div>
</template>
<script>
import userService from '@/services/user.service';

export default {
    data() {
        return {
            list: []
        }
    },
    mounted() {
        this.fetchDetail()
    },
    methods: {
        fetchDetail() {
            this.$store.dispatch('loading')
            userService.getUser3DBettingHistoryDetail(this.$route.params.date).then(({ data }) => {
                console.log(data)
                this.list = data.data
                console.log(this.list)
                this.$store.dispatch('loadingComplete')
            })
        }
    },
}
</script>
<style scoped>
tr {
    padding: 10px 0px !important;
}
</style>