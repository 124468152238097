<template>
    <div class="p-4" v-if="transaction">
        <div class="text-center mb-4">
            <img v-if="transaction.status == 1" src="@/assets/pending.svg" alt="">
            <img v-else-if="transaction.status == 2" src="@/assets/success.svg" alt="">
            <img v-else-if="transaction.status == 3" src="@/assets/fail.svg" alt="">


        </div>
        <div>
            <h1 class="fw-700 fs-18 mb-3">Ks
                <span v-if="transaction.type == '+'">ဝယ်ခြင်း</span>
                <span v-else-if="transaction.type == '-'">ရောင်းခြင်း</span>
                <span v-if="transaction.status == 1">လုပ်ငန်းလုပ်ဆောင်နေဆဲ...</span>
                <span v-else-if="transaction.status == 2">အောင်မြင်ပါသည်</span>
                <span v-else-if="transaction.status == 3"> Rejected</span>

            </h1>
            <div class="d-flex justify-content-between">
                <p class="mb-0 fw-400 fs-13">လုပ်ငန်းကြာချိန် 5min</p>
                <div class="text-primary" v-if="transaction.status == 1">
                    <font-awesome-icon icon="fa-regular fa-clock" class="me-2"></font-awesome-icon>
                    <span class="fw-600 fs-16">{{ $helper.formattedTimer(transaction.remaining_time)
                    }}</span>
                </div>
            </div>



            <div class="mt-2">
                <div class="notice success" v-if="transaction.status == 2">
                    <font-awesome-icon icon="fa-solid fa-circle-check" size="lg"
                        class="text-success me-2"></font-awesome-icon>
                    <p class="mb-0 fw-400 fs-12">
                        <!-- သင့်အကောင့်ထဲမှာ Ks အား Agent ထံသို့ လွှဲပြောင်းပေးလိုက်ပါသည်.. မကြာမီ သင့် Pay  -->
                        သင့်အကောင့်ထဲမှာ Ks အား Agent ထံသို့ လွှဲပြောင်းပေးလိုက်ပါသည်.. မကြာမီ သင့် အကောင့်ထဲသို့ ငွေရောက်ရှိပါမည်.. 
                        <!-- သင့်အကောင့်ထဲသို့ {{ $helper.formattedNumber(transaction.amount) }} Ks ds ရောက်ရှိပါသည် -->
                    </p>
                </div>


                <template v-else-if="transaction.status == 3">
                    <div class="notice warning" v-if="transaction.note">
                        <font-awesome-icon icon="fa-solid fa-circle-exclamation" size="lg"
                            class="text-warning me-2"></font-awesome-icon>
                        <p class="mb-0 fw-400 fs-12">
                            {{ transaction.note }}
                        </p>
                    </div>
                </template>

                <div class="notice warning" v-else>
                    <font-awesome-icon icon="fa-solid fa-circle-exclamation" size="lg"
                        class="text-warning me-2"></font-awesome-icon>
                    <p class="mb-0 fw-400 fs-12">
                        သတ်မှတ်အချိန်အတွင်း Kyatဝယ်ခြင်းမအောင်မြင်ပါ
                        က လွဲခဲ့သည့်ဖုန်းနံပါတ်အား ဆက်သွယ်ပေးပါရန်
                    </p>
                </div>
            </div>

            <div class="horizontal-line my-3"></div>

            <div>
                <h1 class="fw-600 fs-14">ဘောက်ချာအသေးစိတ်</h1>
                <div class="row mb-4">
                    <div class="col-6">
                        <span class="fw-400 fs-13" v-if="transaction.type == '+'">ဝယ်ယူမည့်ပမာဏ</span>
                        <span class="fw-400 fs-13" v-else-if="transaction.type == '-'">ထုတ်မည့်ပမာဏ</span>

                    </div>
                    <div class="col-6 text-end">
                        <span class="fw-400 fs-14">{{ $helper.formattedNumber(transaction.amount) }} Ks</span>
                    </div>
                </div>

                <div class="row mb-4">
                    <div class="col-6">
                        <span class="fw-400 fs-13">လုပ်ဆောင်ချိန်</span>
                    </div>
                    <div class="col-6 text-end">
                        <span class="fw-400 fs-14">{{ transaction.date_time }}</span>
                    </div>
                </div>

                <div class="row mb-4">
                    <div class="col-6">
                        <span class="fw-400 fs-13">ဘောက်ချာနံပါတ်</span>
                    </div>
                    <div class="col-6 text-end">
                        <span class="fw-400 fs-14">{{ transaction.id }}</span>
                    </div>
                </div>

                <div class="row mb-4">
                    <div class="col-6">
                        <span class="fw-400 fs-13">Payအမျိုးအစား</span>
                    </div>
                    <div class="col-6 text-end">
                        <span class="fw-400 fs-14">{{ transaction.payment.name }}</span>
                    </div>
                </div>
            </div>

            <div class="horizontal-line my-3"></div>

            <div>
                <h1 class="fw-600 fs-14">Agent Info</h1>
                <div class="row mb-4">
                    <div class="col-6">
                        <span class="fw-400 fs-13">ဓာတ်ပုံ</span>
                    </div>
                    <div class="col-6 text-end">
                        <img :src="transaction.agent.image" class="w-25" alt="">
                    </div>
                </div>

                <div class="row mb-4">
                    <div class="col-6">
                        <span class="fw-400 fs-13">နာမည်</span>
                    </div>
                    <div class="col-6 text-end">
                        <span class="fw-400 fs-14">{{ transaction.agent.name }}</span>
                    </div>
                </div>

                <div class="row mb-4">
                    <div class="col-6">
                        <span class="fw-400 fs-13">ဖုန်းနံပါတ်</span>
                    </div>
                    <div class="col-6 text-end text-primary">
                        <a :href="'tel:' + transaction.agent.phone"
                            class="fw-500 fs-13 ms-auto text-custom text-decoration-none">
                            <span class="fw-600 fs-14">{{ transaction.agent.phone }}</span>
                            <br>
                            ဖုန်းခေါ်မည်
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import userService from '@/services/user.service'

export default {
    data() {
        return {
            transaction_id: this.$route.params.transaction_id,
            transaction: null
        }
    },
    mounted() {
        this.fetchTransaction()
    },
    methods: {
        fetchTransaction() {
            if (this.transaction_id) {
                this.$store.dispatch('loading')
                userService.getTransactionDetail(this.transaction_id).then(({ data }) => {
                    this.transaction = data.data
                    this.$store.dispatch('loadingComplete')
                    this.counter()
                    console.log(this.transaction)
                })
            }
        },
       counter() {
            let start = this.transaction.remaining_time;
            let that = this;
            let counterInterval = setInterval(function () {
                start--;
                that.transaction.remaining_time = start;
                if (start <= 0) {
                    clearInterval(counterInterval);
                    that.transaction.remaining_time = 0; // Set remaining time to 0 when it reaches negative values
                }
            }, 1000);
        },
    }
}
</script>