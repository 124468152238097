<template>
    <div class="two-d">
        <div class="position-absolute text-white ending-time d-flex align-items-center px-4">
            <span class="ms-auto fw-400 fs-14">ထီပိတ်ချိန် : {{ endTime }}</span>
        </div>
        <div class="banner d-flex flex-column align-items-center justify-content-center">
            <div class="winning-number">
                <span class="fw-600 fs-36">{{ random_number }}</span>
            </div>
            <div class="d-flex align-items-center mt-3">
                <svg v-if="!isStatus" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white"
                    class="bi bi-hourglass-split me-2" viewBox="0 0 16 16">
                    <path
                        d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2h-7zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48V8.35zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z" />
                </svg>
                <font-awesome-icon v-if="isStatus" icon="fa-solid fa-circle-check" size="sm" class="text-white me-2" />
                <span class="fw-400 fs-13 text-white">Updated : {{ updated_at }}</span>
            </div>
        </div>
        <div class="">
            <div class="p-4 border-bottom">
                <h1 class="fw-600 fs-18 mb-0">3D</h1>
                <div class="d-flex align-items-center">
                    <font-awesome-icon icon="fa-solid fa-money-bill" class="text-custom" />
                    <span class="fw-400 fs-14 mx-2">လက်ကျန်ငွေ</span>
                    <span class="fw-600 fs-14">{{ $helper.formattedNumber(this.userInfo?.balance) }} Ks</span>
                </div>
                <div class="actions d-flex justify-content-around my-4">
                    <router-link class="text-decoration-none text-center"
                        :to="{ name: '3d-history', params: { type: '3d' } }">
                        <SecondaryButton :disabled="false" class="px-3 mb-1 action rounded-3">
                            <font-awesome-icon icon="fa-solid fa-clock-rotate-left"></font-awesome-icon>
                        </SecondaryButton>
                        <p class="mb-0 fw-400 fs-13 text-dark">မှတ်တမ်း</p>
                    </router-link>
                    <router-link class="text-decoration-none text-center" :to="{ name: 'winners' }">
                        <SecondaryButton class="px-3 mb-1 action rounded-3">
                            <font-awesome-icon icon="fa-solid fa-award"></font-awesome-icon>
                        </SecondaryButton>
                        <p class="mb-0 fw-400 fs-13 text-dark">အနိုင်ရသူများ</p>
                    </router-link>
                </div>
                <PrimaryButton :disabled="!selectedSection" class="fw-700 fs-14" @click="next()">ထိုးမည်
                </PrimaryButton>
            </div>

            <div class="p-3">
                <ul class="list-group list-group-flush">
                    <li v-for="(section, index ) in history" :key="index" class="list-group-item d-flex align-items-center">
                        <div>
                            <span class="fw-400 fs-12">Date</span>
                            <p class="fw-500 fs-14 mb-0">{{ section.date }}</p>
                        </div>
                        <h1 class="ms-auto fw-500 fs-16">{{ section.winning_number }}</h1>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import PrimaryButton from '@/components/buttons/PrimaryButton.vue';
import userService from '@/services/user.service';
import axios from 'axios';
import moment from 'moment';

export default {
    components: {
        PrimaryButton
    },
    data() {
        return {
            logined: this.$store.state.auth.status.loggedIn,
            currentUser: this.$store.state.auth.user,
            showBetModal: false,
            selectedSection: null,
            opendate: null,
            lottery: false,
            history: [],
            endTime: null,
            updated_at: null,
            random_number: '?',
            isStatus: false,
            userInfo: {}
        }
    },
    mounted() {
        this.fetchWinningNumbers();
        console.log(this.currentUser)
        userService.get3DScheduleHistory().then(({ data }) => {
            this.history = data.data
        }).catch(response => {
            console.log(response)
        })

        userService.get3DScheduleList().then(({ data }) => {
            console.log(data)
            this.selectedSection = data.data
        })

        this.getUserInfo();

    },
    methods: {
        getUserInfo(){
            userService.getUserInfo().then(({ data }) => {
                // console.log(data)
                this.userInfo = data;
            })
        },
        chooseSection(section) {
            this.selectedSection = section
        },
        next() {
            this.$router.push({
                name: '3d-section',
                params: {
                    section: this.selectedSection
                }
            })
        },
        changedate() {
            this.updated_at = moment().format('MMMM D YYYY h:m A')
        },
        fetchWinningNumbers() {
            const fetchData = async () => {
                try {
                    const response = await axios.get('https://aladdin.micropos.app/api/threed/winning_number');
                    const data = response.data;
                    this.opendate = data.opening_date_time;
                    this.opendate = moment(this.opendate, 'DD-MM-YYYY HH:mm A').format('DD MMM YYYY');
                    const currentDate = moment().format('DD MMM YYYY');

                    // const currentTime = moment().format('hh:mm A');
                    // const opentime = moment(this.opendate, 'DD-MM-YYYY hh:mm A').format('hh:mm A');
                    // console.log(currentTime);
                    // console.log(data);

                    if (this.opendate === currentDate) {
                        if (data.ended === true) {
                            this.random_number = data.winning_number;
                            this.endTime = data.end_time;
                            this.isStatus = true;
                        } else {
                            this.random_number = data.pre_winning_number;
                            this.isStatus = false;
                            this.endTime = data.end_time;
                        }
                        this.changedate();
                        setTimeout(() => {
                            this.fetchWinningNumbers();
                        }, 14000);
                    } else {
                        this.random_number = data.pre_winning_number;
                        this.endTime = data.datetime_diff;
                        this.isStatus = true;
                        this.changedate();
                    }
                } catch (error) {
                    console.log(error);
                }
            }

            fetchData();
        },
    }
}
</script>
<style>
.two-d .banner {
    margin-top: -1px;
    height: 21vh;
    background-color: #3367EE;
}

.banner .winning-number {
    border-radius: 50%;
    background-color: #fff;
    color: #3367EE;
    width: 19vw;
    height: 19vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.two-d .content {
    border-radius: 1.2rem 1.2rem 0 0;
    background-color: #fff;
    margin-top: -1rem;
}

.two-d .action {
    width: 13vw !important;
    height: 12vw !important;
}

.ending-time {
    height: 6vh;
    width: 75vw;
    right: 0;
    top: 0;
}

.two-d .section {
    background-color: #F1F3FA;
}

.two-d .section.active {
    color: #fff;
    background-color: #3367EE !important;
}
</style>