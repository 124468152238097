<template>
    <div class="p-4">
        <div class="d-flex">
            <div class="w-50 text-start fw-600 fs-16">ရက်စွဲ</div>
            <div class="w-50 text-end fw-600 fs-16 ">3D ပေါက် ဂဏန်း</div>
        </div>
        <div data-aos="fade-up" data-aos-duration="1500">
            <ul class="list-group list-group-flush mt-3">
                <li v-for="(section, index) in history" :key="index"
                    :class="`${index % 2 === 0 ? 'bg-light' : ''} list-group-item d-flex align-items-center`">
                    <div class="px-2">
                        <span class="fw-400 fs-12">Date</span>
                        <p class="fw-500 fs-14 mb-0">{{ section.date }}</p>
                    </div>
                    <span class=" ms-auto fw-500 fs-16 badge text-bg-success mr-2" style="margin-right:10px">{{
                        section.winning_number }}</span>
                </li>
            </ul>
        </div>

    </div>
</template>
<script>
import userService from '@/services/user.service';

export default {
    data() {
        return {
            history: []
        }
    },
    mounted() {
        this.$store.dispatch('loading')
        userService.get3DScheduleHistory().then((response) => {
            console.log(response.data);
            this.history = response.data.data
            this.$store.dispatch('loadingComplete')
        })
    }
}
</script>
<style>
.winners-nav {
    background-color: #3367EE;
    text-align: center;
    color: white;
    outline: none;
    border: none;
}

.winner-nav-item {
    padding: 10px 0px;
    color: #fff;
    text-decoration: none;
}

.winner-nav-item.active {
    background-color: #3367EE;
}
</style>