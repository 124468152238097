<template>
    <div class="">
        <div class="scroll-container">
            <div class="d-flex winners-nav border-0" style="width: 600px;">
                <div @click="handleThreeD()" style="z-index:1000" class="w-50 winner-nav-item active">3D</div>
                <div @click="handleClick(list.id)" v-for="list in header_list" :key="list"
                    class="bg-danger w-50 winner-nav-item active">{{ list.name }}</div>
            </div>
        </div>



        <div v-if="condition === 1">
            <div class="winner-banner py-4">
                <div v-if="ThreeDWinner[1]" class="second-winner winner text-center">
                    <div class="mb-2 winner-image" style="position: relative;">
                        <img :src="ThreeDWinner[1].customer.avatar" class="mx-auto rounded-circle" alt="">
                        <div class="winner-rank">
                            <span>2</span>
                        </div>
                    </div>
                    <h1 class="winner-name mb-0">{{ ThreeDWinner[1].customer.name }}</h1>
                    <span class="winner-phone">{{ ThreeDWinner[1].amount }}</span>
                </div>

                <div v-if="ThreeDWinner[0]" class="first-winner winner text-center">
                    <div class="mb-2 winner-image" style="position: relative;">
                        <img :src="ThreeDWinner[0].customer.avatar" class="mx-auto rounded-circle" alt="">
                        <div class="winner-rank">
                            <span>1</span>
                        </div>
                    </div>
                    <h1 class="winner-name mb-0">{{ ThreeDWinner[0].customer.name }}</h1>
                    <span class="winner-phone">{{ ThreeDWinner[0].amount }}</span>
                </div>

                <div v-if="ThreeDWinner[2]" class="third-winner winner text-center">
                    <div class="mb-2 winner-image" style="position: relative;">
                        <img :src="ThreeDWinner[2].customer.avatar" class="mx-auto rounded-circle" alt="">
                        <div class="winner-rank">
                            <span>3</span>
                        </div>
                    </div>
                    <h1 class="winner-name mb-0">{{ ThreeDWinner[2].customer.name }}</h1>
                    <span class="winner-phone">{{ ThreeDWinner[2].amount }}</span>
                </div>
            </div>
            <div class="winner-amount p-4">
                <div class="d-flex mb-2 justify-content-between">
                    <h1 class="title">အနိုင်ရငွေ</h1>
                    <router-link :to="{
                        name: 'winners-comment'
                    }" class="comment">Comment ရေးမည်</router-link>
                </div>
                <ul class="list-group">
                    <li v-if="ThreeDWinner[0]" class="list-group-item py-3 px-3">
                        <h1 class="mb-0 label">First Winner</h1>
                        <div class="d-flex justify-content-between">
                            <div class="col-4 d-flex align-items-end">
                                <span class="bet-amount-label mb-0 me-2">Name</span>
                                <h1 class="bet-amount mb-0">{{ ThreeDWinner[0].customer.name }}</h1>
                            </div>
                            <div class="col-8 d-flex align-items-end justify-content-center">
                                <span class="bet-amount-label mb-0 me-2">အနိုင်ရငွေ</span>
                                <h1 class="bet-amount mb-0">{{ ThreeDWinner[0].amount }}</h1>
                            </div>
                        </div>
                    </li>
                    <li v-if="ThreeDWinner[1]" class="list-group-item py-3 px-3">
                        <h1 class="mb-0 label">Second Winner</h1>
                        <div class="d-flex justify-content-between">
                            <div class="col-4 d-flex align-items-end">
                                <span class="bet-amount-label mb-0 me-2">Name</span>
                                <h1 class="bet-amount mb-0">{{ ThreeDWinner[1].customer.name }}</h1>
                            </div>
                            <div class="col-8 d-flex align-items-end justify-content-center">
                                <span class="bet-amount-label mb-0 me-2">အနိုင်ရငွေ</span>
                                <h1 class="bet-amount mb-0">{{ ThreeDWinner[1].amount }}</h1>
                            </div>
                        </div>
                    </li>
                    <li v-if="ThreeDWinner[2]" class="list-group-item py-3 px-3">
                        <h1 class="mb-0 label">Third Winner</h1>
                        <div class="d-flex justify-content-between">
                            <div class="col-4 d-flex align-items-end">
                                <span class="bet-amount-label mb-0 me-2">Name</span>
                                <h1 class="bet-amount mb-0">{{ ThreeDWinner[2].customer.name }}</h1>
                            </div>
                            <div class="col-8 d-flex align-items-end justify-content-center">
                                <span class="bet-amount-label mb-0 me-2">အနိုင်ရငွေ</span>
                                <h1 class="bet-amount mb-0">{{ ThreeDWinner[2].amount }}</h1>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="winners p-4 pt-0">
                <h1 class="title">အခြားထီပေါက်သူများ</h1>
                <ul v-if="ThreeDList" class="list-group list-group-flush">
                    <li v-for="data in ThreeDList" :key="data.id" class="list-group-item d-flex align-items-center">
                        <div class="col-3 d-flex align-items-center">
                            <span class="number me-2">{{ data.id }}</span>
                            <img class="img-fluid rounded-circle" style="width:40px;height:40px"
                                :src="data.customer.avatar">
                        </div>
                        <div class="col-9">
                            <h1 class="mb-0 label">{{ data.customer.name }}</h1>
                            <div class="d-flex justify-content-between">
                                <div class="col-5 d-flex align-items-end">
                                    <span class="bet-amount-label mb-0 me-2">ထိုးငွေ</span>
                                    <h1 class="bet-amount mb-0">{{ data.bet_amount }}</h1>
                                </div>
                                <div class="col-7 d-flex align-items-end justify-content-center">
                                    <span class="bet-amount-label mb-0 me-2">အနိုင်ရငွေ</span>
                                    <h1 class="bet-amount mb-0">{{ data.amount }}</h1>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <div v-if="condition === 2">
            <div class="winner-banner py-4">
                <div v-if="ThreeDWinner[1]" class="second-winner winner text-center">
                    <div class="mb-2 winner-image" style="position: relative;">
                        <img :src="ThreeDWinner[1].customer.avatar" class="mx-auto rounded-circle" alt="">
                        <div class="winner-rank">
                            <span>2</span>
                        </div>
                    </div>
                    <h1 class="winner-name mb-0">{{ ThreeDWinner[1].customer.name }}</h1>
                    <span class="winner-phone">{{ ThreeDWinner[1].amount }}</span>
                </div>

                <div v-if="ThreeDWinner[0]" class="first-winner winner text-center">
                    <div class="mb-2 winner-image" style="position: relative;">
                        <img :src="ThreeDWinner[0].customer.avatar" class="mx-auto rounded-circle" alt="">
                        <div class="winner-rank">
                            <span>1</span>
                        </div>
                    </div>
                    <h1 class="winner-name mb-0">{{ ThreeDWinner[0].customer.name }}</h1>
                    <span class="winner-phone">{{ ThreeDWinner[0].amount }}</span>
                </div>

                <div v-if="ThreeDWinner[2]" class="third-winner winner text-center">
                    <div class="mb-2 winner-image" style="position: relative;">
                        <img :src="ThreeDWinner[2].customer.avatar" class="mx-auto rounded-circle" alt="">
                        <div class="winner-rank">
                            <span>3</span>
                        </div>
                    </div>
                    <h1 class="winner-name mb-0">{{ ThreeDWinner[2].customer.name }}</h1>
                    <span class="winner-phone">{{ ThreeDWinner[2].amount }}</span>
                </div>
            </div>
            <div class="winner-amount p-4">
                <div class="d-flex mb-2 justify-content-between">
                    <h1 class="title">အနိုင်ရငွေ</h1>
                    <router-link :to="{
                        name: 'winners-comment'
                    }" class="comment">Comment ရေးမည်</router-link>
                </div>
                <ul class="list-group">
                    <li v-if="ThreeDWinner[0]" class="list-group-item py-3 px-3">
                        <h1 class="mb-0 label">First Winner</h1>
                        <div class="d-flex justify-content-between">
                            <div class="col-4 d-flex align-items-end">
                                <span class="bet-amount-label mb-0 me-2">Name</span>
                                <h1 class="bet-amount mb-0">{{ ThreeDWinner[0].customer.name }}</h1>
                            </div>
                            <div class="col-8 d-flex align-items-end justify-content-center">
                                <span class="bet-amount-label mb-0 me-2">အနိုင်ရငွေ</span>
                                <h1 class="bet-amount mb-0">{{ ThreeDWinner[0].amount }}</h1>
                            </div>
                        </div>
                    </li>
                    <li v-if="ThreeDWinner[1]" class="list-group-item py-3 px-3">
                        <h1 class="mb-0 label">Second Winner</h1>
                        <div class="d-flex justify-content-between">
                            <div class="col-4 d-flex align-items-end">
                                <span class="bet-amount-label mb-0 me-2">Name</span>
                                <h1 class="bet-amount mb-0">{{ ThreeDWinner[1].customer.name }}</h1>
                            </div>
                            <div class="col-8 d-flex align-items-end justify-content-center">
                                <span class="bet-amount-label mb-0 me-2">အနိုင်ရငွေ</span>
                                <h1 class="bet-amount mb-0">{{ ThreeDWinner[1].amount }}</h1>
                            </div>
                        </div>
                    </li>
                    <li v-if="ThreeDWinner[2]" class="list-group-item py-3 px-3">
                        <h1 class="mb-0 label">Third Winner</h1>
                        <div class="d-flex justify-content-between">
                            <div class="col-4 d-flex align-items-end">
                                <span class="bet-amount-label mb-0 me-2">Name</span>
                                <h1 class="bet-amount mb-0">{{ ThreeDWinner[2].customer.name }}</h1>
                            </div>
                            <div class="col-8 d-flex align-items-end justify-content-center">
                                <span class="bet-amount-label mb-0 me-2">အနိုင်ရငွေ</span>
                                <h1 class="bet-amount mb-0">{{ ThreeDWinner[2].amount }}</h1>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="winners p-4 pt-0">
                <h1 class="title">အခြားထီပေါက်သူများ</h1>
                <ul v-if="ThreeDList" class="list-group list-group-flush">
                    <li v-for="data in ThreeDList" :key="data.id" class="list-group-item d-flex align-items-center">
                        <div class="col-3 d-flex align-items-center">
                            <span class="number me-2">{{ data.id }}</span>
                            <img class="img-fluid rounded-circle" style="width:40px;height:40px"
                                :src="data.customer.avatar">
                        </div>
                        <div class="col-9">
                            <h1 class="mb-0 label">{{ data.customer.name }}</h1>
                            <div class="d-flex justify-content-between">
                                <div class="col-5 d-flex align-items-end">
                                    <span class="bet-amount-label mb-0 me-2">ထိုးငွေ</span>
                                    <h1 class="bet-amount mb-0">{{ data.bet_amount }}</h1>
                                </div>
                                <div class="col-7 d-flex align-items-end justify-content-center">
                                    <span class="bet-amount-label mb-0 me-2">အနိုင်ရငွေ</span>
                                    <h1 class="bet-amount mb-0">{{ data.amount }}</h1>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <div v-if="condition === 3">
            <div class="winner-banner py-4">
                <div v-if="ThreeDWinner[1]" class="second-winner winner text-center">
                    <div class="mb-2 winner-image" style="position: relative;">
                        <img :src="ThreeDWinner[1].customer.avatar" class="mx-auto rounded-circle" alt="">
                        <div class="winner-rank">
                            <span>2</span>
                        </div>
                    </div>
                    <h1 class="winner-name mb-0">{{ ThreeDWinner[1].customer.name }}</h1>
                    <span class="winner-phone">{{ ThreeDWinner[1].amount }}</span>
                </div>

                <div v-if="ThreeDWinner[0]" class="first-winner winner text-center">
                    <div class="mb-2 winner-image" style="position: relative;">
                        <img :src="ThreeDWinner[0].customer.avatar" class="mx-auto rounded-circle" alt="">
                        <div class="winner-rank">
                            <span>1</span>
                        </div>
                    </div>
                    <h1 class="winner-name mb-0">{{ ThreeDWinner[0].customer.name }}</h1>
                    <span class="winner-phone">{{ ThreeDWinner[0].amount }}</span>
                </div>

                <div v-if="ThreeDWinner[2]" class="third-winner winner text-center">
                    <div class="mb-2 winner-image" style="position: relative;">
                        <img :src="ThreeDWinner[2].customer.avatar" class="mx-auto rounded-circle" alt="">
                        <div class="winner-rank">
                            <span>3</span>
                        </div>
                    </div>
                    <h1 class="winner-name mb-0">{{ ThreeDWinner[2].customer.name }}</h1>
                    <span class="winner-phone">{{ ThreeDWinner[2].amount }}</span>
                </div>
            </div>
            <div class="winner-amount p-4">
                <div class="d-flex mb-2 justify-content-between">
                    <h1 class="title">အနိုင်ရငွေ</h1>
                    <router-link :to="{
                        name: 'winners-comment'
                    }" class="comment">Comment ရေးမည်</router-link>
                </div>
                <ul class="list-group">
                    <li v-if="ThreeDWinner[0]" class="list-group-item py-3 px-3">
                        <h1 class="mb-0 label">First Winner</h1>
                        <div class="d-flex justify-content-between">
                            <div class="col-4 d-flex align-items-end">
                                <span class="bet-amount-label mb-0 me-2">Name</span>
                                <h1 class="bet-amount mb-0">{{ ThreeDWinner[0].customer.name }}</h1>
                            </div>
                            <div class="col-8 d-flex align-items-end justify-content-center">
                                <span class="bet-amount-label mb-0 me-2">အနိုင်ရငွေ</span>
                                <h1 class="bet-amount mb-0">{{ ThreeDWinner[0].amount }}</h1>
                            </div>
                        </div>
                    </li>
                    <li v-if="ThreeDWinner[1]" class="list-group-item py-3 px-3">
                        <h1 class="mb-0 label">Second Winner</h1>
                        <div class="d-flex justify-content-between">
                            <div class="col-4 d-flex align-items-end">
                                <span class="bet-amount-label mb-0 me-2">Name</span>
                                <h1 class="bet-amount mb-0">{{ ThreeDWinner[1].customer.name }}</h1>
                            </div>
                            <div class="col-8 d-flex align-items-end justify-content-center">
                                <span class="bet-amount-label mb-0 me-2">အနိုင်ရငွေ</span>
                                <h1 class="bet-amount mb-0">{{ ThreeDWinner[1].amount }}</h1>
                            </div>
                        </div>
                    </li>
                    <li v-if="ThreeDWinner[2]" class="list-group-item py-3 px-3">
                        <h1 class="mb-0 label">Third Winner</h1>
                        <div class="d-flex justify-content-between">
                            <div class="col-4 d-flex align-items-end">
                                <span class="bet-amount-label mb-0 me-2">Name</span>
                                <h1 class="bet-amount mb-0">{{ ThreeDWinner[2].customer.name }}</h1>
                            </div>
                            <div class="col-8 d-flex align-items-end justify-content-center">
                                <span class="bet-amount-label mb-0 me-2">အနိုင်ရငွေ</span>
                                <h1 class="bet-amount mb-0">{{ ThreeDWinner[2].amount }}</h1>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="winners p-4 pt-0">
                <h1 class="title">အခြားထီပေါက်သူများ</h1>
                <ul v-if="ThreeDList" class="list-group list-group-flush">
                    <li v-for="data in ThreeDList" :key="data.id" class="list-group-item d-flex align-items-center">
                        <div class="col-3 d-flex align-items-center">
                            <span class="number me-2">{{ data.id }}</span>
                            <img class="img-fluid rounded-circle" style="width:40px;height:40px"
                                :src="data.customer.avatar">
                        </div>
                        <div class="col-9">
                            <h1 class="mb-0 label">{{ data.customer.name }}</h1>
                            <div class="d-flex justify-content-between">
                                <div class="col-5 d-flex align-items-end">
                                    <span class="bet-amount-label mb-0 me-2">ထိုးငွေ</span>
                                    <h1 class="bet-amount mb-0">{{ data.bet_amount }}</h1>
                                </div>
                                <div class="col-7 d-flex align-items-end justify-content-center">
                                    <span class="bet-amount-label mb-0 me-2">အနိုင်ရငွေ</span>
                                    <h1 class="bet-amount mb-0">{{ data.amount }}</h1>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <div v-if="condition === 4">
            <div class="winner-banner py-4">
                <div v-if="ThreeDWinner[1]" class="second-winner winner text-center">
                    <div class="mb-2 winner-image" style="position: relative;">
                        <img :src="ThreeDWinner[1].customer.avatar" class="mx-auto rounded-circle" alt="">
                        <div class="winner-rank">
                            <span>2</span>
                        </div>
                    </div>
                    <h1 class="winner-name mb-0">{{ ThreeDWinner[1].customer.name }}</h1>
                    <span class="winner-phone">{{ ThreeDWinner[1].amount }}</span>
                </div>

                <div v-if="ThreeDWinner[0]" class="first-winner winner text-center">
                    <div class="mb-2 winner-image" style="position: relative;">
                        <img :src="ThreeDWinner[0].customer.avatar" class="mx-auto rounded-circle" alt="">
                        <div class="winner-rank">
                            <span>1</span>
                        </div>
                    </div>
                    <h1 class="winner-name mb-0">{{ ThreeDWinner[0].customer.name }}</h1>
                    <span class="winner-phone">{{ ThreeDWinner[0].amount }}</span>
                </div>

                <div v-if="ThreeDWinner[2]" class="third-winner winner text-center">
                    <div class="mb-2 winner-image" style="position: relative;">
                        <img :src="ThreeDWinner[2].customer.avatar" class="mx-auto rounded-circle" alt="">
                        <div class="winner-rank">
                            <span>3</span>
                        </div>
                    </div>
                    <h1 class="winner-name mb-0">{{ ThreeDWinner[2].customer.name }}</h1>
                    <span class="winner-phone">{{ ThreeDWinner[2].amount }}</span>
                </div>
            </div>
            <div class="winner-amount p-4">
                <div class="d-flex mb-2 justify-content-between">
                    <h1 class="title">အနိုင်ရငွေ</h1>
                    <router-link :to="{
                        name: 'winners-comment'
                    }" class="comment">Comment ရေးမည်</router-link>
                </div>
                <ul class="list-group">
                    <li v-if="ThreeDWinner[0]" class="list-group-item py-3 px-3">
                        <h1 class="mb-0 label">First Winner</h1>
                        <div class="d-flex justify-content-between">
                            <div class="col-4 d-flex align-items-end">
                                <span class="bet-amount-label mb-0 me-2">Name</span>
                                <h1 class="bet-amount mb-0">{{ ThreeDWinner[0].customer.name }}</h1>
                            </div>
                            <div class="col-8 d-flex align-items-end justify-content-center">
                                <span class="bet-amount-label mb-0 me-2">အနိုင်ရငွေ</span>
                                <h1 class="bet-amount mb-0">{{ ThreeDWinner[0].amount }}</h1>
                            </div>
                        </div>
                    </li>
                    <li v-if="ThreeDWinner[1]" class="list-group-item py-3 px-3">
                        <h1 class="mb-0 label">Second Winner</h1>
                        <div class="d-flex justify-content-between">
                            <div class="col-4 d-flex align-items-end">
                                <span class="bet-amount-label mb-0 me-2">Name</span>
                                <h1 class="bet-amount mb-0">{{ ThreeDWinner[1].customer.name }}</h1>
                            </div>
                            <div class="col-8 d-flex align-items-end justify-content-center">
                                <span class="bet-amount-label mb-0 me-2">အနိုင်ရငွေ</span>
                                <h1 class="bet-amount mb-0">{{ ThreeDWinner[1].amount }}</h1>
                            </div>
                        </div>
                    </li>
                    <li v-if="ThreeDWinner[2]" class="list-group-item py-3 px-3">
                        <h1 class="mb-0 label">Third Winner</h1>
                        <div class="d-flex justify-content-between">
                            <div class="col-4 d-flex align-items-end">
                                <span class="bet-amount-label mb-0 me-2">Name</span>
                                <h1 class="bet-amount mb-0">{{ ThreeDWinner[2].customer.name }}</h1>
                            </div>
                            <div class="col-8 d-flex align-items-end justify-content-center">
                                <span class="bet-amount-label mb-0 me-2">အနိုင်ရငွေ</span>
                                <h1 class="bet-amount mb-0">{{ ThreeDWinner[2].amount }}</h1>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="winners p-4 pt-0">
                <h1 class="title">အခြားထီပေါက်သူများ</h1>
                <ul v-if="ThreeDList" class="list-group list-group-flush">
                    <li v-for="data in ThreeDList" :key="data.id" class="list-group-item d-flex align-items-center">
                        <div class="col-3 d-flex align-items-center">
                            <span class="number me-2">{{ data.id }}</span>
                            <img class="img-fluid rounded-circle" style="width:40px;height:40px"
                                :src="data.customer.avatar">
                        </div>
                        <div class="col-9">
                            <h1 class="mb-0 label">{{ data.customer.name }}</h1>
                            <div class="d-flex justify-content-between">
                                <div class="col-5 d-flex align-items-end">
                                    <span class="bet-amount-label mb-0 me-2">ထိုးငွေ</span>
                                    <h1 class="bet-amount mb-0">{{ data.bet_amount }}</h1>
                                </div>
                                <div class="col-7 d-flex align-items-end justify-content-center">
                                    <span class="bet-amount-label mb-0 me-2">အနိုင်ရငွေ</span>
                                    <h1 class="bet-amount mb-0">{{ data.amount }}</h1>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>



    </div>
</template>
<style>
.winners-nav {
    background-color: #3367EE;
    text-align: center;
    color: white;
    outline: none;
    border: none;
}

.winner-nav-item {
    padding: 10px 0px;
}

.winner-nav-item.active {
    background-color: #2B5DDC;
}

.winner {
    display: flex;
    flex-direction: column;
}


.winner-rank span {
    background-color: #ffffff;
    width: 20px;
    height: 20px;
    color: #2B5DDC;
    border-radius: 50%;
    bottom: -8px;
    left: 0;
    right: 0;
    margin: auto;
    position: absolute;
    font-weight: 600;
    font-size: 14px;
}

.first-winner {
    justify-content: start;
    margin: 0px 20px;
}

.second-winner {
    justify-content: end;
}

.third-winner {
    justify-content: end;
}

.winner-banner {
    background: url('../assets/winner-banner.svg');
    height: 21vh;
    position: relative;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    border-radius: 0px 0px 1rem 1rem;
}

.winner-name {
    color: white;
    font-weight: 600;
    font-size: 12px;
}

.winner-phone {
    color: #CEDFFF;
    font-weight: 400;
    font-size: 12px;
}

.winner-amount .title,
.winners .title {
    font-weight: 700;
    font-size: 16px;
}

.winner-amount .comment {
    font-weight: 500;
    font-size: 14px;
    text-decoration: none;
}

.winner-amount .label,
.winners .label {
    font-weight: 600;
    font-size: 14px;
}

.winner-amount .bet-amount-label,
.winners .bet-amount-label {
    font-weight: 400;
    font-size: 11px;
}

.winner-amount .bet-amount,
.winners .bet-amount {
    font-weight: 600;
    font-size: 12px;
}

.winner-image img {
    width: 15vw;
    height: 15vw;
}

.winners .number {
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
}

.scroll-container {
    height: 44.5px;
    overflow-y: auto;
    scrollbar-width: none;
    /* For Firefox */
    -ms-overflow-style: none;
    /* For IE and Edge */
}

.scroll-container::-webkit-scrollbar {
    display: none;
    /* For Chrome, Safari, and Opera */
}
</style>
<script>
import userService from '@/services/user.service';

export default {
    data() {
        return {

            header_list: null,
            ThreeD: false,
            ThreeDList: null,
            ThreeDWinner: null,
        };
    },
    async mounted() {
        await this.getList();
        await this.handleThreeD();
    },
    methods: {
        async handleThreeD() {
            this.condition = 4;
            this.$store.dispatch('loading');
            try {
                const { data } = await userService.ThreeDTopCustomer();
                this.$store.dispatch('loadingComplete');
                this.ThreeDList = data;
                this.ThreeDWinner = this.ThreeDList.splice(0, 3);
            } catch (error) {
                console.error(error);
            }
        },
        async getList() {
            this.$store.dispatch('loading');
            try {
                const response = await fetch('https://aladdin.micropos.app/api/header_list');
                if (response.ok) {
                    const data = await response.json();
                    this.$store.dispatch('loadingComplete');
                    this.header_list = data;

                } else {
                    throw new Error('Failed to fetch header list');
                }
            } catch (error) {
                console.error(error);
            }
        },
        async handleClick(id) {
            this.condition = id;
            this.$store.dispatch('loading');
            try {
                const url = `https://aladdin.micropos.app/api/twod/top_customers?twod_type_id=${id}`;
                const response = await fetch(url);
                if (response.ok) {
                    const data = await response.json();
                    this.$store.dispatch('loadingComplete');
                    this.ThreeDList = data;
                    this.ThreeDWinner = this.ThreeDList.splice(0, 3);
                } else {
                    throw new Error('Failed to fetch top customers');
                }

            } catch (error) {
                console.error(error);
            }
        },
    },
};
</script>