<template>
    <div>
        <div class="d-flex winners-nav border-0" style="font-size: 12px;">
            <a class="active w-50 winner-nav-item">Admin</a>
            <router-link class="w-50 winner-nav-item" :to="{ name: 'agent-transaction-history' }">
                Agent
            </router-link>
        </div>
    </div>
    <div class="p-4 mt-2">
        <div class="row">
            <div class="col-4 px-1">
                <div class="card p-1 border-0" style="background-color: #8dabf5;color: white;font-size: 12px;">
                    <p class="p-0 m-0 text-center">Deposits</p>
                    <p class="p-0 m-0 text-center">{{ history.statistics && history.statistics.deposits ?
                        history.statistics.deposits : '' }}</p>
                </div>
            </div>
            <div class="col-4">
                <div class="card p-1 border-0" style="background-color: #8dabf5;color: white;font-size: 12px;">
                    <p class="p-0 m-0 text-center">withdraw</p>
                    <p class="p-0 m-0 text-center">{{ history.statistics && history.statistics.withdraw ?
                        history.statistics.withdraw : '' }}</p>
                </div>
            </div>
            <div class="col-4">
                <div class="card p-1 border-0" style="background-color: #8dabf5;color: white;font-size: 12px;">
                    <p class="p-0 m-0 text-center">Transaction</p>
                    <p class="p-0 m-0 text-center">{{ history.statistics && history.statistics.transactions_count ?
                        history.statistics.transactions_count : '' }}</p>
                </div>
            </div>
        </div>

        <div v-for="(transactions, index) in history.transactions" :key="index" class="my-4">
            {{ index }}
            <div v-for="(transaction, index2) in transactions" :key="index2" class="mb-2 bg-light py-2 px-3 rounded">
                <div style="display: flex;justify-content:space-between;align-items: center;font-size: 15px;">
                    <div>
                        <span v-if="transaction.type == 'Deposits'">
                            <small class="text-muted"> Deposits</small>
                        </span>
                        <span v-if="transaction.type == 'Withdraw'">
                            <small class="text-muted">Withdraw</small>
                        </span>
                        <div>
                            <small class="text-muted">{{ formatDate(transaction.created_at) }}</small>
                        </div>
                    </div>
                    <div>
                        <span v-if="transaction.type == 'Deposits'">
                            +
                        </span>
                        <span v-if="transaction.type == 'Withdraw'">
                            -
                        </span>
                        <small> {{ transaction.amount }}</small>
                    </div>
                </div>
                <font-awesome-icon size="xl" class="text-custom mx-2" icon="fa-brands fa-cash" />
            </div>
        </div>
    </div>
</template>
<script>
import userService from '@/services/user.service';
export default {
    data() {
        return {
            history: []
        }
    },
    mounted() {
        this.fetchHistory()
    },
    methods: {
        fetchHistory() {
            this.$store.dispatch('loading')
            userService.getTransactionHistory().then(({ data }) => {
                this.$store.dispatch('loadingComplete')
                this.history = data.data
                console.log(this.history);
            })
        },
        formatDate(timestamp) {
            const date = new Date(timestamp);
            const hours = date.getHours();
            const minutes = date.getMinutes();
            const ampm = hours >= 12 ? 'PM' : 'AM';
            const formattedHours = hours % 12 || 12;
            const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
            return `${formattedHours}:${formattedMinutes} ${ampm}`;
        },
    }
}
</script>
<style>
.transaction-line {
    height: 1px;
    margin: 0;
    padding: 0;
    border: 1px solid #EBF0FD;
}

.winners-nav {
    background-color: #3367EE;
    text-align: center;
    color: white;
    outline: none;
    border: none;
}

.winner-nav-item {
    padding: 10px 0px;
    color: #fff;
    text-decoration: none;
}

.winner-nav-item.active {
    color: white !important;
    background-color: #2B5DDC !important;
}
</style>

