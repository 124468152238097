<template>
    <div class="p-4">
        <form class="d-flex flex-column h-100">

            <template v-if="step == 1">
                <div>
                    <div class="d-flex flex-wrap mb-4 align-items-center">
                        <div class="col-5 mx-auto">
                            <img :src="user.avatar" class="profile-image rounded-circle" alt="">
                        </div>
                    </div>


                    <FormGroup v-model="oldPassword" label="Old Password" placeholder="****" type="text">
                    </FormGroup>
                    <FormGroup v-model="newPassword" label="New Password" placeholder="****" type="text">
                    </FormGroup>

                    <FormGroup v-model="ComfirmPassword" label="Comfirm Password" placeholder="****" type="text">
                    </FormGroup>


                    <div class="col-6 mx-auto">
                        <PrimaryButton @click="handleRegister()">Change Password</PrimaryButton>
                    </div>
                </div>
            </template>
        </form>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    name: "ChangePasswordComponent",
    data() {
        return {
            step: 1,
            OTPSeconds: 60,
            OTPLabel: "Get OTP",
            previewImage: null,
            user: this.$store.state.auth.user,
            oldPassword: null,
            newPassword: null,
            ComfirmPassword: null,

        };
    },
    methods: {
        next() {
            this.$router.push({
                name: 'new-pin',
                params: {
                    phone: this.phone,
                    name: this.name
                }
            })
        },
        getOTP() {
            console.log(this.OTPSeconds)
            var otpInterval = setInterval(() => {
                this.OTPSeconds--;

                if (this.OTPSeconds <= 0) {
                    clearInterval(otpInterval)
                    this.OTPSeconds = 60;
                    this.OTPLabel = "Get OTP"
                }

                this.OTPLabel = this.OTPSeconds + "s"
            }, 1000)
        },
        chooseImage() {
            this.$refs.chooseImage.click()
        },
        imageChoosed(e) {
            const image = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
                this.previewImage = e.target.result;
            };
            this.image = e.target.files[0]

        },
        handleRegister() {
            event.preventDefault();
            let token = JSON.parse(localStorage.getItem('user'));
            token = token.token
            let data = new FormData();
            data.append('old_password', this.oldPassword);
            data.append('new_password', this.newPassword);
            data.append('new_password_confirmation', this.ComfirmPassword);
            this.$store.dispatch('loading')
            axios.post("https://aladdin.micropos.app/api/password/update", data, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
                .then(response => {
                    console.log(response);
                    this.$store.dispatch('loadingComplete');
                    this.$store.dispatch('showToast',"Successfully Changed!")
                })
                .catch(error => {
                    console.log(error);
                     this.$store.dispatch('showError',"Please fill Correct Data")
                });

        }
    },
}
</script>

<style>
.profile-image {
    width: 100px;
    height: 100px;
    background-color: #EBF0FD;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.get-otp {
    font-weight: 800;
    position: relative;
    top: 7px;
}
</style>
