<template>
    <div class="p-4">
        <form @submit="handleRegister()" class="d-flex flex-column h-100">

            <template v-if="step == 1">
                <div>
                    <div class="d-flex flex-wrap mb-4 align-items-center">
                        <div class="col-5 mx-auto">
                            <img :src="user.avatar" class="profile-image rounded-circle" alt="">
                        </div>
                    </div>

                    <FormGroup :readOnly="true" v-model="user.name" label="Username" placeholder="Enter your name"
                        type="text">
                    </FormGroup>
                    <FormGroup v-model="user.phone" :readOnly="true" label="Phone Number" placeholder="09123456789"
                        type="number">
                    </FormGroup>


                    <div class="col-6 mx-auto">
                        <router-link :to="{ name: 'profile.update' }">
                            <PrimaryButton>ပြင်ဆင်မည်</PrimaryButton>
                        </router-link>
                    </div>
                </div>
            </template>
        </form>
    </div>
</template>
<script>
export default {
    data() {
        return {
            step: 1,
            OTPSeconds: 60,
            OTPLabel: "Get OTP",
            previewImage: null,
            user: this.$store.state.auth.user
        };
    },
    methods: {
        next() {
            this.$router.push({
                name: 'new-pin',
                params: {
                    phone: this.phone,
                    name: this.name
                }
            })
        },
        getOTP() {
            console.log(this.OTPSeconds)
            var otpInterval = setInterval(() => {
                this.OTPSeconds--;

                if (this.OTPSeconds <= 0) {
                    clearInterval(otpInterval)
                    this.OTPSeconds = 60;
                    this.OTPLabel = "Get OTP"
                }

                this.OTPLabel = this.OTPSeconds + "s"
            }, 1000)
        },
        chooseImage() {
            this.$refs.chooseImage.click()
        },
        imageChoosed(e) {
            const image = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
                this.previewImage = e.target.result;
            };
            this.image = e.target.files[0]

        },
        handleRegister() {
            event.preventDefault();
            let data = new FormData();
            data.append('image', this.image);
            data.append('phone', this.phone);
            data.append('name', this.name);
            data.append('password', this.password);
            this.$store.dispatch('loading')
            this.$store.dispatch('auth/register', data).then((data) => {
                this.$store.dispatch('loadingComplete')
                if (data.success) {
                    this.$router.push({ name: 'get-started' })
                }
            }).catch(({ response }) => {
                if (response.status == 422) {
                    this.errors = response.data.errors
                    if (this.errors.phone && this.errors.phone.length > 0) {
                        this.step = 1;
                    }
                }
            })
        }
    },
}
</script>

<style>
.profile-image {
    width: 100px;
    height: 100px;
    background-color: #EBF0FD;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.get-otp {
    font-weight: 800;
    position: relative;
    top: 7px;
}
</style>
