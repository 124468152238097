<template>
    <div class="p-4">
        <div class="d-flex flex-column justify-content-between h-100">
            <h1 class="title mb-3">မိတ်ဆက်ကုပ်ဖြည့်မည်</h1>
            <p class="subtitle">မိတ်ဆက်ကုပ်ရှိပါက ဖြည့်စွပ်နိုင်ပါသည်</p>

            <div class="d-flex justify-content-center my-4">
                <ReferrIcon class="mx-auto"></ReferrIcon>
            </div>

            <div>
                <FormGroup v-model="code" :error="errors.code" label="မိတ်ဆက်ကုပ် (Optional)"
                    placeholder="Enter referral code" type="number" class="mb-4">
                </FormGroup>
                <div class="w-25 mx-auto">
                    <PrimaryButton>ဖြည့်မည်</PrimaryButton>
                </div>
            </div>

            <PrimaryButton type="button" @click="confirm()" class="login-btn mt-auto">Confirm</PrimaryButton>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            code: "",
            errors: {}
        }
    },
    methods: {
        confirm() {
            this.$router.push({ name: 'get-started' })
        }
    }
}
</script>