import { createWebHistory, createRouter } from "vue-router";
import IndexView from "@/views/IndexView.vue";
import LoginView from "@/views/LoginView.vue";
import RegisterView from "@/views/RegisterView.vue";
import CreatePinView from "@/views/CreatePinView.vue";
import ReferralView from "@/views/ReferralView.vue";
import GetStartedView from "@/views/GetStartedView.vue";
import HomeView from "@/views/HomeView.vue";

import WinnersView from "@/views/WinnersView.vue";
import WinnersCommentView from "@/views/WinnersCommentView.vue";

import TopUpIndexView from "@/views/TopUp/IndexView.vue";
import TopUpChooseAgentView from "@/views/TopUp/ChooseAgentView.vue";
import TopUpAgentView from "@/views/TopUp/AgentView.vue";
import TopUpConfirmView from "@/views/TopUp/ConfirmView.vue";



import TransactionPendingView from "@/views/TransactionPendingView.vue";

import WithDrawsView from "@/views/WithDrawsView.vue";
import EnterPinView from "@/views/EnterPinView.vue";

import WithDrawIndexView from "@/views/Withdraw/IndexView.vue";
import WithDrawChooseAgentView from "@/views/Withdraw/ChooseAgentView.vue";
import WithDrawAgentView from "@/views/Withdraw/AgentView.vue";
import WithDrawConfirmView from "@/views/Withdraw/ConfirmView.vue";

import TransactionHistoryView from "@/views/TransactionHistoryView.vue";
import TransactionHistoryFromAgent from "@/views/TransactionHistoryFromAgent.vue"
import TransactionDetailView from "@/views/TopUp/TransactionView.vue";
import TopUpTransactionView from "@/views/TopUp/TransactionView.vue"

import ProfileView from "@/views/ProfileView.vue";
import ViewProfileView from "@/views/ViewProfileView.vue";
import UpdateProfileView from "@/views/UpdateProfileView.vue";

import ServicesView from "@/views/ServicesView.vue";

import BettingListView from "@/views/BettingListView.vue";
import ThreeDBettingListView from '@/views/BettingThreeDList.vue'

import TwoDView from "@/views/2DView.vue";
import TwoDSection from "@/views/2DSection.vue";
import TwoDBettingListDetailView from "@/views/2DBettingListDetailView.vue";
import TwoDClosingDaysView from "@/views/2DClosingDaysView.vue";

import ThreeDView from "@/views/3DView.vue";
import ThreeDSection from "@/views/3DSection.vue";
import ThreeDBettingListDetailView from "@/views/3DBettingListDetailView.vue";


import NotificationsView from "@/views/NotificationsView.vue";
import PrivacyPolicyView from "@/views/PrivacyPolicyView.vue";

import ChangePassword from "@/views/ChangePassword.vue";
import PendingView from "@/components/Pending.vue"
import TwoDHistory from '@/views/2DHistory.vue';
import ThreeDHistory from '@/views/3DHistory.vue';

import middleware from "@/middleware";


const routes = [
    {
        path: "/index",
        name: "index",
        component: IndexView,
        meta: {
            navbar: false,
            footerNavbar: false,
            backButton: true,
        }
    },
    {
        path: "/login",
        name: "login",
        component: LoginView,
        meta: {
            navbar: true,
            footerNavbar: false,
            backButton: true,

        }
    },
    {
        path: "/pending",
        name: "pending",
        component: PendingView,
        meta: {
            navbar: false,
            footerNavbar: false,
            backButton: true,

        }
    },
    {
        path: "/register",
        name: "register",
        component: RegisterView,
        meta: {
            navbar: true,
            footerNavbar: false,
            backButton: true,

        }
    },
    {
        path: "/new-pin",
        name: "new-pin",
        component: CreatePinView,
        meta: {
            navbar: true,
            footerNavbar: false,
            backButton: true,

        }
    },
    {
        path: "/referral",
        name: "referral",
        component: ReferralView,
        meta: {
            navbar: true,
            footerNavbar: false,
            backButton: true,

        }
    },
    {
        path: "/get-started",
        name: "get-started",
        component: GetStartedView,
        meta: {
            navbar: false,
            footerNavbar: false,
            backButton: true,

        }
    },
    {
        path: "/changepassword",
        name: "changepassword",
        component: ChangePassword,
        meta: {
            navbar: true,
            footerNavbar: false,
            backButton: true,

        }
    },
    {
        path: "/",
        name: "home",
        component: HomeView,
        meta: {
            navbar: false,
            footerNavbar: true,
            backButton: true,
        }
    },
    {
        path: "/winners",
        name: "winners",
        component: WinnersView,
        meta: {
            title: "ထီပေါက်သူ",
            navbar: true,
            footerNavbar: true,
            backButton: true,
        }
    },
    {
        path: "/winners/comment",
        name: "winners-comment",
        component: WinnersCommentView,
        meta: {
            title: "2D ထီပေါက်သူ",
            navbar: true,
            footerNavbar: false,
            backButton: true,
        }
    },
    {
        path: "/top-up",
        name: "top-up",
        component: TopUpIndexView,
        meta: {
            title: "ငွေဖြည့်မည်",
            navbar: true,
            footerNavbar: false,
            backButton: true,
        }
    },
    {
        path: "/top-up/agent",
        name: "top-up.choose-agent",
        component: TopUpChooseAgentView,
        meta: {
            title: "Agent ရွေးမည်",
            navbar: true,
            footerNavbar: false,
            backButton: true,
        }
    },
    {
        path: "/top-up/agent/:agent",
        name: "top-up.agent",
        component: TopUpAgentView,
        meta: {
            title: "Agent ရွေးမည်",
            navbar: true,
            footerNavbar: false,
            backButton: true,
        }
    },
    {
        path: "/top-up/agent/:agent/payment/:payment",
        name: "top-up.confirm",
        component: TopUpConfirmView,
        meta: {
            navbar: true,
            footerNavbar: false,
            backButton: true,
        }
    },
    {
        path: "/transaction/:id",
        name: "transaction",
        component: TopUpTransactionView,
        meta: {
            navbar: true,
            footerNavbar: false,
            backButton: true,
        }
    },
    {
        path: "/transaction/:transaction_id/detail",
        name: "transaction-detail",
        component: TransactionDetailView,
        meta: {
            title: "ငွေသွင်း/ငွေထုတ်မှတ်တမ်း",
            navbar: true,
            footerNavbar: false,
            backButton: true,
        }
    },
    {
        path: "/transaction/:type/pending",
        name: "transaction-pending",
        component: TransactionPendingView,
        meta: {
            navbar: false,
            footerNavbar: false,
            backButton: true,

        }
    },
    {
        path: "/withdraws",
        name: "withdraws",
        component: WithDrawsView,
        meta: {
            title: "Cash Out",
            navbar: true,
            footerNavbar: false,
            backButton: true,

        }
    },
    {
        path: "/withdraw",
        name: "withdraw",
        component: WithDrawIndexView,
        meta: {
            navbar: true,
            footerNavbar: false,
            backButton: true,

        }
    },
    {
        path: "/withdraw/agent",
        name: "withdraw.choose-agent",
        component: WithDrawChooseAgentView,
        meta: {
            title: "Agent ရွေးမည်",
            navbar: true,
            footerNavbar: false,
            backButton: true,

        }
    },
    {
        path: "/withdraw/agent/:agent",
        name: "withdraw.agent",
        component: WithDrawAgentView,
        meta: {
            title: "Agent ဆီမှ Unitထုတ်မည်",
            navbar: true,
            footerNavbar: false,
            backButton: true,

        }
    },
    {
        path: "/withdraw/agent/:agent/payment/:payment/confirm",
        name: "withdraw.confirm",
        component: WithDrawConfirmView,
        meta: {
            navbar: true,
            footerNavbar: false,
            backButton: true,

        }
    },
    {
        path: "/enter-pin",
        name: "enter-pin",
        component: EnterPinView,
        meta: {
            title: "Enter Pin",
            navbar: true,
            footerNavbar: false,
            backButton: true,
        }
    },
    {
        path: "/transaction-history",
        name: "transaction-history",
        component: TransactionHistoryView,
        meta: {
            title: "ငွေသွင်း/ငွေထုတ်မှတ်တမ်း",
            navbar: true,
            footerNavbar: false,
            backButton: true,
            requireAuth: true

        }
    },
    {
        path: "/transaction/:transaction_id/detail",
        name: "transaction-detail",
        component: TransactionDetailView,
        meta: {
            title: "ငွေသွင်း/ငွေထုတ်မှတ်တမ်း",
            navbar: true,
            footerNavbar: false,
            backButton: true,
            requireAuth: true

        }
    },
    {
        path: "/transaction-history/agent",
        name: "agent-transaction-history",
        component: TransactionHistoryFromAgent,
        meta: {
            title: "ငွေသွင်း/ငွေထုတ်မှတ်တမ်း",
            navbar: true,
            footerNavbar: false,
            backButton: true,
            requireAuth: true

        }
    },
    {
        path: "/services",
        name: "services",
        component: ServicesView,
        meta: {
            title: "Services",
            navbar: true,
            footerNavbar: true,
            backButton: false
        }
    }, {
        path: "/profile",
        name: "profile",
        component: ProfileView,
        meta: {
            title: "Profile",
            navbar: true,
            footerNavbar: true,
            backButton: false
        }
    },
    {
        path: "/profile/view",
        name: "profile.view",
        component: ViewProfileView,
        meta: {
            title: "ကျွန်ုပ်အချက်အလက်",
            navbar: true,
            footerNavbar: false,
            backButton: true,
            requireAuth: true
        }
    },
    {
        path: "/profile/update",
        name: "profile.update",
        component: UpdateProfileView,
        meta: {
            title: "ကျွန်ုပ်အချက်အလက်",
            navbar: true,
            footerNavbar: false,
            backButton: true,
            requireAuth: true

        }
    },
    {
        path: "/2d",
        name: "2d",
        component: TwoDView,
        meta: {
            navbar: true,
            footerNavbar: false,
            backButton: true,
            requireAuth: true

        }
    },
    {
        path: "/2d/section/:section",
        name: "2d-section",
        component: TwoDSection,
        meta: {
            title: "2D ထိုးမည်",
            navbar: true,
            footerNavbar: false,
            backButton: true,
            requireAuth: true

        }
    },
    {
        path: "/3d/section/:section",
        name: "3d-section",
        component: ThreeDSection,
        meta: {
            title: "3D ထိုးမည်",
            navbar: true,
            footerNavbar: false,
            backButton: true,
            requireAuth: true
        }
    },
    {
        path: "/betting/list",
        name: "betting-list",
        component: BettingListView,
        meta: {
            title: "မှတ်တမ်း",
            navbar: true,
            footerNavbar: false,
            backButton: true,
            requireAuth: true
        }
    },
    {
        path: "/ThreeDbetting/list",
        name: "ThreeDBettingList",
        component: ThreeDBettingListView,
        meta: {
            title: "မှတ်တမ်း",
            navbar: true,
            footerNavbar: false,
            backButton: true,
        }
    },
    {
        path: "/2d/betting/list/:date",
        name: "2d-betting-list-detail",
        component: TwoDBettingListDetailView,
        meta: {
            title: "မှတ်တမ်း",
            navbar: true,
            footerNavbar: false,
            backButton: true,
        },
    },
    {
        path: "/3d/betting/list/:date",
        name: "3d-betting-list-detail",
        component: ThreeDBettingListDetailView,
        meta: {
            title: "မှတ်တမ်း",
            navbar: true,
            footerNavbar: false,
            backButton: true,
        },
    },
    {
        path: "/2d/closing-days",
        name: "2d-closing-days",
        component: TwoDClosingDaysView,
        meta: {
            title: "ထီပိတ်ရက်များ",
            navbar: true,
            footerNavbar: false,
            backButton: true,
        }
    },
    {
        path: "/notifications",
        name: "notifications",
        component: NotificationsView,
        meta: {
            title: "အသိပေးချက်များ",
            navbar: true,
            footerNavbar: false,
            backButton: true,
        }
    },
    {
        path: "/privacy-policy",
        name: "privacy-policy",
        component: PrivacyPolicyView,
        meta: {
            title: "Privacy Policy",
            navbar: true,
            footerNavbar: false,
            backButton: true,
        }
    },


    {
        path: "/3d",
        name: "3d",
        component: ThreeDView,
        meta: {
            navbar: true,
            footerNavbar: false,
            backButton: true,
            requireAuth: true

        }
    },

    {
        path: "/2d/history",
        name: "2d-history",
        component: TwoDHistory,
        meta: {
            navbar: true,
            footerNavbar: false,
            backButton: true,
            requireAuth: true

        }
    },
    {
        path: "/3d/history",
        name: "3d-history",
        component: ThreeDHistory,
        meta: {
            navbar: true,
            footerNavbar: false,
            backButton: true,
            requireAuth: true

        }
    },


];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

middleware(router)

export default router;