<template>
    <div class="p-4">
        <ul class="list-group list-group-flush">
            <li v-for="(day, index) in days" :key="index" class="list-group-item d-flex py-3 align-items-center">
                <font-awesome-icon icon="fa-solid fa-calendar-xmark" class="me-4 text-danger" size="lg" />
                <div style="flex-grow: 1;">
                    <h1 class="mb-1 fw-600 fs-14">{{ day.date }}</h1>
                    <div class="d-flex justify-content-between fw-400 fs-12">
                        {{ day.title }}
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>
<script>
import userService from '@/services/user.service';

export default {
    data() {
        return {
            days: []
        }
    },
    mounted() {
        userService.getClosingDays().then(({ data }) => {
            this.days = data.data
        })
    }
}
</script>