<template>
    <div class="toast-box rounded-3 px-3 py-2" v-if="message">
        <p class="mb-0 fw-400 fs-14">{{ message }}</p>
    </div>
</template>
<script>
export default {
    props: {
        message: {
            type: String
        }
    }
}
</script>
<style scoped>
.toast-box {
    background-color: #000000FF;
    max-width: 90vw;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 3vh;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    z-index: 1000;
    color: white;
}
</style>