<template>
    <div>
        <div class="d-flex winners-nav border-0">
            <router-link class="w-50 winner-nav-item" :to="{ name: 'betting-list' }">
                2D ထိုးမှတ်တမ်း
            </router-link>
            <a @click="type = '3d'" class="w-50 winner-nav-item" :class="{
                active: type == '3d'
            }">3D မှတ်တမ်း</a>
        </div>
        <!-- <template v-if="type == '3d'">
            <ThreeDHistory></ThreeDHistory>
        </template>
        <template v-else>
            <ThreeDBettingListView></ThreeDBettingListView>
        </template> -->
        <ThreeDBettingListView></ThreeDBettingListView>
    </div>
</template>
<script>
import ThreeDBettingListView from '@/views/3DBettingListView.vue';
// import ThreeDHistory from '@/views/3DHistory.vue';

export default {
    components: {
        ThreeDBettingListView
    },
    data() {
        return {
            type: "3d"
        }
    },
    mounted() {
        if (this.$route.params.type) {
            this.type = this.$route.params.type
        }
    }
}
</script>
<style>
.winners-nav {
    background-color: #3367EE;
    text-align: center;
    color: white;
    outline: none;
    border: none;
}

.winner-nav-item {
    padding: 10px 0px;
    color: #fff;
    text-decoration: none;
}

.winner-nav-item.active {
    color: white !important;
    background-color: #2B5DDC !important;
}
</style>