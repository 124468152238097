<template>
    <div class="p-4">
        <form @submit="handleLogin()" class="d-flex flex-column justify-content-between h-100">
            <h1 class="title mb-3">Log In</h1>
            <p class="subtitle mb-4">အကောင့်ဝင်ရန် သင့်ဖုန်းနံပါတ်ရိုက်ထည့်ပါ</p>

            <div>
                <FormGroup v-model="phone" :errors="errors.phone" label="Phone Number" type="number" class="mb-4">
                </FormGroup>
                <FormGroup v-model="password" :errors="errors.password" label="PIN / Password" type="password" class="mb-4">
                </FormGroup>
                <p class="text-end text-custom sub-lable font-noto">
                    <router-link class="w-50 me-1 text-decoration-none" :to="{ name: 'register' }">
                        အကောင့်ဖွင့်ရန်
                    </router-link>
                </p>
            </div>

            <PrimaryButton type="submit" class="login-btn mt-auto">Log in</PrimaryButton>
        </form>
    </div>
</template>
<script>
export default {
    data() {
        return {
            phone: "",
            password: "",
            errors: {
                phone: []
            }
        }
    },
    created() {
        if (this.$store.state.auth.status.loggedIn) {
            this.$router.push({ name: 'home' })
        }
    },
    methods: {
        handleLogin() {
            this.$store.dispatch('loading')
            console.log(this.$store.state.index.loading)
            event.preventDefault()
            this.$store.dispatch('auth/login', {
                phone: this.phone,
                password: this.password
            }).then((data) => {
                console.log(data);
                this.$store.dispatch('loadingComplete')

                if (data.success) {
                    this.$store.dispatch('showToast', 'Login Successful!')
                    this.$router.push({ name: 'home' })
                }
            }).catch(({ response }) => {
                console.log(response.data.message)
                this.$store.dispatch('showError', response.data.message);
                // if (response.status == 422) {
                //     this.errors = response.data.errors
                // }
            })
        }
    }
}
</script>
<style></style>
