<template>
    <div class="two-d">
        <div class="position-absolute text-white ending-time d-flex align-items-center px-4">
            <span class="ms-auto fw-400 fs-14">ထီပိတ်ချိန် : {{ end_time }}</span>
        </div>
        <div class="banner d-flex flex-column align-items-center justify-content-center">
            <div class="winning-number">
                <span id="randomNumber" class="fw-600 fs-36" :class="{ blinker: !isStatus }" v-if="renderTwodLiveNumber">{{
                    random_number
                }}</span>
            </div>

            <div class="d-flex align-items-center mt-3">
                <svg v-if="!isStatus" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white"
                    class="bi bi-hourglass-split me-2" viewBox="0 0 16 16">
                    <path
                        d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2h-7zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48V8.35zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z" />
                </svg>
                <font-awesome-icon v-if="isStatus" icon="fa-solid fa-circle-check" size="sm" class="text-white me-2" />
                <span class="fw-400 fs-13 text-white">Updated : {{ updated_at }}</span>
            </div>
        </div>
        <div class="content">
            <div class="p-3 border-bottom">
                <h1 class="fw-600 fs-18 mb-0">2D</h1>
                <div class="d-flex align-items-center">
                    <font-awesome-icon icon="fa-solid fa-money-bill" class="text-custom" />
                    <span class="fw-400 fs-14 mx-2">လက်ကျန်ငွေ</span>
                    <span class="fw-600 fs-14">{{ $helper.formattedNumber(this.userInfo.balance) }} Ks</span>
                </div>
                <div class="actions d-flex justify-content-around my-4">
                    <router-link class="text-decoration-none text-center"
                        :to="{ name: '2d-history' }">
                        <SecondaryButton :disabled="false" class="px-3 mb-1 action rounded-3">
                            <font-awesome-icon icon="fa-solid fa-clock-rotate-left"></font-awesome-icon>
                        </SecondaryButton>
                        <p class="mb-0 fw-400 fs-13 text-dark">မှတ်တမ်း</p>
                    </router-link>
                    <router-link class="text-decoration-none text-center" :to="{ name: 'winners' }">
                        <SecondaryButton class="px-3 mb-1 action rounded-3">
                            <font-awesome-icon icon="fa-solid fa-award"></font-awesome-icon>
                        </SecondaryButton>
                        <p class="mb-0 fw-400 fs-13 text-dark">အနိုင်ရသူများ</p>
                    </router-link>
                    <router-link class="text-decoration-none text-center" :to="{ name: '2d-closing-days' }">
                        <SecondaryButton class="px-3 mb-1 action rounded-3">
                            <font-awesome-icon icon="fa-solid fa-calendar-xmark" />
                        </SecondaryButton>
                        <p class="mb-0 fw-400 fs-13 text-dark">ထီပိတ်ရက်များ</p>
                    </router-link>
                </div>
                <PrimaryButton class="fw-700 fs-14" @click="showBetModal = true">ထိုးမည်</PrimaryButton>
            </div>

            <div class="p-3" v-for="(lottery, index) in lottries" :key="index">
                <h1 class="fw-600 fs-14">{{ index }}</h1>
                <ul class="list-group list-group-flush">
                    <li v-for="(section, index ) in lottery" :key="index" class="list-group-item d-flex align-items-center">
                        <div>
                            <div>
                                <font-awesome-icon v-if="section.day" icon="fa-solid fa-sun" class="text-custom me-2" />
                                <font-awesome-icon v-else icon="fa-solid fa-moon" class="text-custom me-2" />
                                <span class="fw-400 fs-16">{{ section.opening_time }}</span>
                            </div>
                            <div v-if="section.has_set_value">
                                <span class="fw-400 fs-12 me-3">Set <span class="text-custom">{{ section.ended ?
                                    $helper.formattedNumber(section.set) : '...................'
                                }}</span></span>
                                <span class="fw-400 fs-12">Value <span class="text-custom">{{
                                    section.ended ? $helper.formattedNumber(section.value) : '...................'
                                }}</span></span>
                            </div>
                        </div>
                        <h1 class="ms-auto fw-500 fs-18">{{ section.ended ? section.winning_number : '??' }}</h1>
                    </li>
                </ul>
            </div>

            <!-- <div class="p-3">
                <h1 class="fw-600 fs-14">New York 2D</h1>
                <ul class="list-group list-group-flush">
                    <li class="list-group-item d-flex align-items-center">
                        <div>
                            <div>
                                <font-awesome-icon icon="fa-solid fa-sun" class="text-custom me-2" />
                                <span class="fw-400 fs-16">10:30 AM</span>
                            </div>
                            <div>
                                <span class="fw-400 fs-12 me-3">Set <span class="text-custom">{{
                                    $helper.formattedNumber(1599.82)
                                }}</span></span>
                                <span class="fw-400 fs-12">Value <span class="text-custom">{{
                                    $helper.formattedNumber(32122.25)
                                }}</span></span>
                            </div>
                        </div>
                        <h1 class="ms-auto fw-600 fs-24">06</h1>
                    </li>
                    <li class="list-group-item d-flex align-items-center">
                        <div>
                            <div>
                                <font-awesome-icon icon="fa-solid fa-moon" class="text-custom me-2" />
                                <span class="fw-400 fs-16">12:01 PM</span>
                            </div>
                            <div>
                                <span class="fw-400 fs-12 me-3">Set <span class="text-custom">{{
                                    $helper.formattedNumber(1599.82)
                                }}</span></span>
                                <span class="fw-400 fs-12">Value <span class="text-custom">{{
                                    $helper.formattedNumber(32122.25)
                                }}</span></span>
                            </div>
                        </div>
                        <h1 class="ms-auto fw-600 fs-24">22</h1>
                    </li>
                    <li class="list-group-item d-flex align-items-center">
                        <div>
                            <div>
                                <font-awesome-icon icon="fa-solid fa-sun" class="text-custom me-2" />
                                <span class="fw-400 fs-16">2:30 PM</span>
                            </div>
                            <div>
                                <span class="fw-400 fs-12 me-3">Set <span class="text-custom">{{
                                    $helper.formattedNumber(1599.82)
                                }}</span></span>
                                <span class="fw-400 fs-12">Value <span class="text-custom">{{
                                    $helper.formattedNumber(32122.25)
                                }}</span></span>
                            </div>
                        </div>
                        <h1 class="ms-auto fw-600 fs-24">16</h1>
                    </li>
                    <li class="list-group-item d-flex align-items-center">
                        <div>
                            <div>
                                <font-awesome-icon icon="fa-solid fa-moon" class="text-custom me-2" />
                                <span class="fw-400 fs-16">6:00 PM</span>
                            </div>
                            <div>
                                <span class="fw-400 fs-12 me-3">Set <span class="text-custom">{{
                                    $helper.formattedNumber(1599.82)
                                }}</span></span>
                                <span class="fw-400 fs-12">Value <span class="text-custom">{{
                                    $helper.formattedNumber(32122.25)
                                }}</span></span>
                            </div>
                        </div>
                        <h1 class="ms-auto fw-600 fs-24">27</h1>
                    </li>
                </ul>
            </div>

            <div class="p-3">
                <h1 class="fw-600 fs-14">Internet</h1>
                <ul class="list-group list-group-flush">
                    <li class="list-group-item d-flex align-items-center">
                        <div>
                            <font-awesome-icon icon="fa-solid fa-sun" class="text-custom me-2" />
                            <span class="fw-400 fs-16">10:30 AM</span>
                        </div>
                        <h1 class="ms-auto fw-600 fs-24">06</h1>
                    </li>
                    <li class="list-group-item d-flex align-items-center">
                        <div>
                            <font-awesome-icon icon="fa-solid fa-moon" class="text-custom me-2" />
                            <span class="fw-400 fs-16">5:00 PM</span>
                        </div>
                        <h1 class="ms-auto fw-600 fs-24">38</h1>
                    </li>
                    <li class="list-group-item d-flex align-items-center">
                        <div>
                            <font-awesome-icon icon="fa-solid fa-moon" class="text-custom me-2" />
                            <span class="fw-400 fs-16">2:30 AM</span>
                        </div>
                        <h1 class="ms-auto fw-600 fs-24">75</h1>
                    </li>
                </ul>
            </div> -->

        </div>
        <ModalBox :show="showBetModal" @close="showBetModal = false">
            <template v-slot:header>ထိုးမည့် Section ရွေးပါ</template>
            <template v-slot:body>
                <template  v-if="showSection" >
                     <div>
                            <div class="pb-3" :class="{ 'py-3': x !== 0, 'border-bottom': x + 1 !== lottries.length }"
                                v-for="(lottery, x) in lottries" :key="x">
                                <h1 class="fw-600 fs-14">{{ x }}</h1>
                                <div class="d-flex flex-wrap">
                                    <div v-for="(value, y) in lottery" :key="y"
                                        :class="{ 'ps-2': y % 2 !== 0, 'pe-2': y % 2 === 0 }" class="col-6 mb-2">
                                        <div v-if="value.ending" class="text-center section rounded py-2 fw-600 fs-14 ended">
                                            {{ $helper.formattedTime(value.opening_time) + ' (' + value.multiply + 'z)' }}
                                        </div>
                                        <div v-else class="text-center section rounded py-2 fw-600 fs-14"
                                            :class="{ 'active': selectedSection == value.id }" @click="chooseSection(value.id)">
                                            {{ $helper.formattedTime(value.opening_time) + ' (' + value.multiply + 'z)' }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </template>

                <div v-if="!showSection" >
                       <div  class="pb-3 text-center">
                         <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor"
                                    class="bi bi-exclamation-diamond text-danger mt-3" viewBox="0 0 16 16">
                                    <path
                                        d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435zm1.4.7a.495.495 0 0 0-.7 0L1.134 7.65a.495.495 0 0 0 0 .7l6.516 6.516a.495.495 0 0 0 .7 0l6.516-6.516a.495.495 0 0 0 0-.7L8.35 1.134z" />
                                    <path
                                        d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                                </svg>
                            </div>
                            <h1 class="fw-600 fs-14 text-danger  my-4 pt-1 "> ယနေ့တွင် 2D ပိတ်ပါသည် ။</h1>
                       </div>
                    </div>

                <div class="d-flex mt-2">
                    <div class="col-6 pe-1">
                        <SecondaryButton class="fw-700 fs-14" @click="showBetModal = false">နောက်မှ</SecondaryButton>
                    </div>
                    <div class="col-6 ps-1">
                        <PrimaryButton class="fw-700 fs-14" @click="next()" :disabled="selectedSection == null">
                            ဆက်သွားမည်</PrimaryButton>
                    </div>
                </div>
            </template>
        </ModalBox>
    </div>
</template>
<script>
import PrimaryButton from '@/components/buttons/PrimaryButton.vue';
import ModalBox from '@/components/ModalComponent.vue';
import userService from '@/services/user.service';
import moment from 'moment';
import axios from 'axios';

export default {
    components: {
        ModalBox,
        PrimaryButton
    },
   computed: {
       showSection() {
            const currentDate = new Date(); // Get the current date
            const currentDay = currentDate.getDay();
            const isNotWeekend = currentDay !== 0 && currentDay !== 6;

            // Return the combined condition using the reactive data property
           console.log(isNotWeekend);
           if (this.isApiDateEqual === true) {
               return false;
           }else{
            return true && isNotWeekend;
           }
        },
    },

    data() {
        return {
            logined: this.$store.state.auth.status.loggedIn,
            currentUser: this.$store.state.auth.user,
            showBetModal: false,
            selectedSection: null,
            lottries: [],
            end_time: null,
            random_number: '00',
            updated_at: moment().format('MMMM D YYYY h:m A'),
            renderTwodLiveNumber: true,
            isStatus: false,
            isApiDateEqual: false,
            userInfo: {}
        }
    },
    mounted() {
        const currentDate = new Date(); // Get the current date
        const formattedDate = currentDate.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });

        userService.getClosingDays().then(({ data }) => {
            data.data.forEach((item) => {
                console.log(item.date)
                if (formattedDate === item.date)
                {
                    this.isApiDateEqual = true;
                }
            });
        });
        const currentHour = new Date().getHours();
        if (currentHour >= 17 || currentHour < 5) {
            this.random_number = '??';
        } else {
            this.fetchWinningNumbers();
        }
        userService.get2DScheduleList().then(({ data }) => {
            if (data.success) {
                this.lottries = data.data
            }
        })
        this.getUserInfo();
    },
    methods: {
        getUserInfo(){
            userService.getUserInfo().then(({ data }) => {
                // console.log(data)
                this.userInfo = data;
            })
        },
        chooseSection(section) {
            this.selectedSection = section
        },
        next() {
            this.$router.push({
                name: '2d-section',
                params: {
                    section: this.selectedSection
                }
            })
        },
        fetchWinningNumbers() {
            const fetchData = async () => {
                try {
                    const response = await axios.get('https://aladdin.micropos.app/api/twod/winning_number');
                    const data = response.data;

                    // if (response.data.length === 0) {
                    //     this.random_number = '??';
                    // }

                    // console.log(data);

                    if (data.ended === false) {
                        this.isStatus = false;
                        this.end_time = data.end_time;
                        this.getTwodLiveNumber();
                        console.log(data.opening_date_time);

                    } else {
                        this.renderTwodLiveNumber = true;
                        this.$forceUpdate();
                        this.isStatus = true;
                        this.random_number = data.winning_number;
                        this.end_time = data.end_time;
                        console.log(data.winning_number);
                    }
                } catch (error) {
                    console.error(error);
                }
            };

            fetchData(); // Execute the function immediately when reaching the page

            setInterval(fetchData, 14000); // Execute the function every 14 seconds
        },
        changeRamdomNumber() {
            let that = this
            setInterval(function () {
                that.random_number = String(Math.floor(Math.random() * 10)) + String(Math.floor(Math.random() * 10))
                that.updated_at = moment().format('MMMM D YYYY h:m A')
            }, 1000 * 5)
        },
        getTwodLiveNumber() {
            this.renderTwodLiveNumber = false;
            userService.getTwodLiveNumber().then(($data) => {
                // console.log($data);
                this.renderTwodLiveNumber = true;
                this.random_number = $data.data.live_number;
            })
        },

        pollData() {
            setInterval(() => {
                this.getTwodLiveNumber();
            }, 10000 * 14)
        },
    },

}
</script>
<style>
.two-d .banner {
    margin-top: -1px;
    height: 21vh;
    background-color: #3367EE;
}

.banner .winning-number {
    border-radius: 50%;
    background-color: #fff;
    color: #3367EE;
    width: 19vw;
    height: 19vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.two-d .content {
    border-radius: 1.2rem 1.2rem 0 0;
    background-color: #fff;
    margin-top: -1rem;
}

.two-d .action {
    width: 13vw !important;
    height: 12vw !important;
}

.ending-time {
    height: 6vh;
    width: 75vw;
    right: 0;
    top: 0;
}

.two-d .section {
    background-color: #F1F3FA;
}

.two-d .section.active {
    color: #fff;
    background-color: #3367EE !important;
}

.ended {
    color: #9e9e9e !important;
}

.blinker {
    animation: blinker 2s linear infinite;
}

@keyframes blinker {

    0% {
        opacity: 1;
    }

    25% {
        opacity: 0.5;
    }

    50% {
        opacity: 0;
    }

    75% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}
</style>