<template>
    <div class="home">
        <div class="home-banner p-4">
            <div class="d-flex justify-content-between">
                <div class="text-white">
                    <h1 class="banner-title">Aladdin 2D</h1>
                </div>
                <router-link :to="{ name: 'notifications' }">
                    <font-awesome-icon class="text-white" icon="fa-solid fa-bell" size="lg"></font-awesome-icon>
                </router-link>
            </div>
        </div>


        <div v-if="logined" class="welcome-box mx-3 pb-2">
            <div class="d-flex align-items-center user-info bg-primary p-3">
                <div>
                    <img v-if="this.userInfo.avatar != null" :src="this.userInfo.avatar" class="rounded-circle" width="50"
                        style="aspect-ratio: 1/1;border-width: .1rem;border-width: .3rem;" alt="">
                    <img v-else src="https://aladdin.micropos.app/user.png" class="rounded-circle" width="50"
                        style="aspect-ratio: 1/1;border-width: .1rem;border-width: .3rem;" alt="">
                </div>
                <div class="text-white text-start ms-2">
                    <h1 class="user-name mb-1">{{ this.userInfo?.name }}</h1>
                    <p class="user-phone mb-0">{{ this.userInfo?.phone }}</p>
                </div>
                <router-link :to="{ name: 'profile.view' }" class=" ms-auto">
                    <div class="user-info-edit rounded-circle">
                        <font-awesome-icon class="text-white" icon="fa-solid fa-pencil"></font-awesome-icon>
                    </div>
                </router-link>
            </div>

            <div>
                <div class="user-balance-info text-start px-3 py-2 d-flex align-items-center">
                    <div>
                        <p class="user-phone mb-0">Available Balance</p>
                        <h1 class="user-balance mb-0">{{ this.userInfo.balance ?
                            $helper.formattedNumber(this.userInfo.balance)
                            : 0 }} Ks</h1>
                    </div>
                    <router-link :to="{ name: 'top-up' }" class="ms-auto text-decoration-none">
                        <span class="ms-auto text-primary user-balance-deposit-label">ငွေဖြည့်မည်</span>
                    </router-link>
                </div>

                <div class="user-actions d-flex justify-content-around">
                    <router-link class="text-decoration-none" :to="{ name: 'top-up' }">
                        <SecondaryButton :disabled="false" class="px-3 mb-1 action">
                            <font-awesome-icon icon="fa-solid fa-plus"></font-awesome-icon>
                        </SecondaryButton>
                        <p class="mb-0 fw-400 fs-13">ငွေဖြည့်</p>
                    </router-link>
                    <router-link class="text-decoration-none" :to="{ name: 'withdraw' }">
                        <SecondaryButton class="px-3 mb-1 action">
                            <font-awesome-icon icon="fa-solid fa-circle-up"></font-awesome-icon>
                        </SecondaryButton>
                        <p class="mb-0 fw-400 fs-13">ငွေထုတ်</p>
                    </router-link>
                    <router-link class="text-decoration-none" :to="{ name: 'transaction-history' }">
                        <SecondaryButton class="px-3 mb-1 action">
                            <font-awesome-icon icon="fa-solid fa-clock-rotate-left" />
                        </SecondaryButton>
                        <p class="mb-0 fw-400 fs-13">မှတ်တမ်း</p>
                    </router-link>
                </div>

            </div>

        </div>
        <div v-else class="welcome-box p-3 mx-3">
            <img src="@/assets/home-logo.svg">
            <h1 class="welcome-title">Welcome to Aladdin 2D</h1>
            <p class="subtitle">အကောင့်ဖွင့်ပါ (သို့) Log in ဝင်ပါ</p>
            <div class="d-flex">
                <router-link class="w-50 me-1 text-decoration-none" :to="{ name: 'login' }">
                    <SecondaryButton>Login</SecondaryButton>
                </router-link>
                <router-link class="w-50 me-1 text-decoration-none" :to="{ name: 'register' }">
                    <PrimaryButton>Register</PrimaryButton>
                </router-link>
            </div>
        </div>

        <div class="p-3">
            <div class="ad-box mb-3 d-flex flex-column">
                <div style="position: relative;">
                    <carousel :items-to-show="1" style="overflow: hidden;">
                        <slide v-for="slide in bannerImages" :key="slide">
                            <img :src="slide.image" alt="">
                        </slide>

                        <template #addons>
                            <navigation />
                            <div style="position: absolute;bottom:0;right:0;left:0;margin:auto">
                                <pagination />
                            </div>
                        </template>
                    </carousel>
                </div>
                <div class="mt-auto ad-description-box">
                    <marquee class="ad-description text-primary" behavior="" direction="">
                        <font-awesome-icon class="me-2" icon="fa-solid fa-bullhorn"></font-awesome-icon>
                        {{ homeBannerText }}
                    </marquee>
                </div>

            </div>

            <div class="games-box mb-2">
                <h1 class="title">ဂိမ်းများ</h1>
                <div class="row">
                    <router-link :to="{ name: '2d' }" class="col-6">
                        <img src="@/assets/2d.svg" class="w-100">
                    </router-link>
                    <router-link :to="{ name: '3d' }" class="col-6">
                        <img src="@/assets/3d.svg" class="w-100">
                    </router-link>
                </div>
            </div>


<!-- 
            <router-link :to="{ name: 'betting-list' }" class="w-100">
                <OutlineButton>
                    ထိုးမှတ်တမ်း
                    <font-awesome-icon icon="fa-solid fa-arrow-right"></font-awesome-icon>
                </OutlineButton>
            </router-link> -->
        </div>
    </div>
</template>
<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import PrimaryButton from '@/components/buttons/PrimaryButton.vue';
import userService from '@/services/user.service';

export default {
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
        PrimaryButton
    },
    data() {
        return {
            logined: this.$store.state.auth.status.loggedIn,
            currentUser: this.$store.state.auth.user,
            userInfo: '',
        }
    },
    computed: {
        homeBannerText() {
            return this.$store.state.index.homeBannerText
        },
        bannerImages() {
            return this.$store.state.index.homeBannerImages;
        }
    },
    methods: {
        getUserInfo() {
            if (this.logined) {
                userService.getUserInfo().then(({ data }) => {
                    // console.log(data)
                    this.userInfo = data;
                })
            }
        }
    },
    mounted() {
        // let userphone = localStorage.getItem("userphone");
        // let userpassword = localStorage.getItem("userpassword");
        // console.log(userphone);
        // console.log(userpassword);
        // if (userpassword && userphone) {
        //     this.$store.dispatch('loading')
        //     this.$store.dispatch('auth/login', {
        //         phone: userphone,
        //         password: userpassword
        //     }).then((data) => {
        //         console.log(data);
        //         this.$store.dispatch('loadingComplete')

        //         if (data.success) {
        //             // this.$store.dispatch('showToast', 'Login Successful!')
        //             this.$router.push({ name: 'home' })
        //         }
        //     }).catch(({ response }) => {
        //         console.log(response.data.message)
        //         this.$store.dispatch('showError', response.data.message);
        //         if (response.status == 422) {
        //             this.errors = response.data.errors
        //         }
        //     })
        // }
        // console.log(this.$store.state)
        // if (this.logined) {
        //     this.$store.dispatch('auth/updateUserInfo')
        // }
    },
    created() {
        this.getUserInfo();

    }
}
</script>
<style>
.home-banner {
    height: 25vh;
    width: 100%;
    background: url('../assets/home-banner.png');
    background-position: center;
    background-size: 100%;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

.banner-title {
    font-weight: 600;
    font-size: 16px;
}

.welcome-box {
    margin-top: -30px;
    background-color: #fff;
    box-shadow: 0px 4px 24px 0px #6D7FA333;
    border-radius: 14px;
    text-align: center;
}

.welcome-box .user-info {
    border-top-right-radius: 14px;
    border-top-left-radius: 14px;
}

.ad-box {
    border: 2px solid #ECEEF5;
    border-radius: 14px;
}

.ad-box img {
    border-radius: 14px 14px 0px 0px;
    /* height: 200px; */
    width: 100%;
    aspect-ratio: 16/9;
    object-fit: cover;
}

.ad-box .ad-description {
    font-size: 13px;
    font-weight: 400;
}

.ad-box .ad-description-box {
    background-color: #447BE833;
    padding-top: 5px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

.user-balance-deposit-label {
    font-weight: 400;
    font-size: 12px;
}

.user-name {
    font-weight: 600;
    font-size: 14px;
}

.user-phone {
    font-weight: 400;
    font-size: 12px;
}

.user-balance {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 0;
}

.user-info-edit {
    background: #00000033;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.welcome-box .welcome-title {
    font-weight: 600;
    font-size: 18px;
}

.games-box .title {
    font-weight: 700;
    font-size: 16px;
}

.user-actions .action {
    width: 13vw !important;
    height: 12vw !important;
}

.home {
    overflow: scroll;
    /* padding-bottom: 10vh !important; */
}

.carousel {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}
</style>

