<template>
    <div class="p-4">
        <form class="d-flex flex-column h-100">

            <template v-if="step == 1">
                <h1 class="title mb-3">Register</h1>
                <p class="subtitle mb-4">အကောင့်ပြုလုပ်ရန် သင့်ဖုန်းအချက်အလက်ရိုက်ထည့်ပါ</p>

                <div>
                    <div class="d-flex flex-wrap mb-4 align-items-center">
                        <div class="col-5">
                            <img v-if="previewImage" :src="previewImage" class="profile-image rounded-circle" alt="">
                            <div v-else class="profile-image rounded-circle">
                                <font-awesome-icon icon="fa-solid fa-user" size="2xl" class="text-primary" />
                            </div>
                            <input ref="chooseImage" type="file" class="d-none" @change="imageChoosed">
                        </div>
                        <div class="col-6">
                            <SecondaryButton type="button" @click="takePhoto()" class="mb-2 fw-600 fs-14">Take Photo
                            </SecondaryButton>
                            <SecondaryButton type="button" @click="chooseImage()" class="fw-600 fs-14">Upload Image
                            </SecondaryButton>
                        </div>
                        <div class="col-12">
                            <small class="text-danger fw-400 fs-12" v-for="(message, index) in errors.image" :key="index">{{
                                message }}</small>
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                        <div class="col-12 position-relative">
                            <FormGroup @keypress="handlePhoneChange" v-model="phone" :success="success.phone"
                                :errors="errors.phone" label="Phone Number" placeholder="09123456789" type="number"
                                class="mb-0">
                            </FormGroup>
                            <span v-if="status" class="text-danger"
                                style="font-size:12px;font-weight:bold;position:relative;bottom:0px">Phone number is
                                invalid!</span>
                        </div>

                    </div>
                    <FormGroup :required="true" v-model="name" :errors="errors.name" label="Username"
                        placeholder="Enter your name" type="text">
                    </FormGroup>
                    <FormGroup :required="true" v-model="password" :errors="errors.name" label="Password (8) လုံးထည့်ပေးပါ"
                        placeholder="Enter your password" type="text">
                    </FormGroup>
                    <FormGroup :required="true" v-model="comfirmpassword" :errors="errors.name"
                        label="Comfirm Password (8) လုံးထည့်ပေးပါ" placeholder="Enter your Comfirm Password" type="text">
                    </FormGroup>
                </div>
                <PrimaryButton @click="handleRegister()" class="login-btn mt-auto">Register</PrimaryButton>
            </template>

        </form>
    </div>
</template>
<script>
import axios from 'axios';

export default {
    data() {
        return {
            step: 1,
            OTPSeconds: 60,
            OTPLabel: "Get OTP",
            previewImage: null,
            phone: "",
            name: "",
            password: "",
            comfirmpassword: "",
            image: "",
            errors: {},
            success: {},
            status: false,
            registerUrl: "https://aladdin.micropos.app/api/register"
        };
    },
    created() {
        console.log(this.phone);
    },
    methods: {
        handlePhoneChange(value) {
            const phoneNumber = value.target.value;
            // console.log('Phone Number:', phoneNumber);

            if (phoneNumber.length > 1) {
                const firstTwoNumbers = phoneNumber.substring(-1, 2);
                if (firstTwoNumbers !== '09') {
                    this.status = true;
                } else {
                    this.status = false;
                }
            }
        },

        // next() {
        //     this.$router.push({
        //         name: 'new-pin',
        //         params: {
        //             phone: this.phone,
        //             name: this.name
        //         }
        //     })
        // },
        takePhoto() {
            const inputElement = this.$refs.chooseImage;

            // Clear previously selected files, if any
            inputElement.value = '';

            // Set the capture attribute to "camera" to show only the camera option
            inputElement.setAttribute("capture", "camera");

            // Trigger the click event on the input element
            inputElement.click();
        },
        // getOTP() {
        //     userService.getOTP({
        //         phone: this.phone
        //     }).then(({ data }) => {
        //         if (data.success) {
        //             this.success.phone = data.message
        //             console.log(this.success.phone)
        //             var otpInterval = setInterval(() => {
        //                 this.OTPSeconds--;

        //                 if (this.OTPSeconds <= 0) {
        //                     clearInterval(otpInterval)
        //                     this.OTPSeconds = 60;
        //                     this.OTPLabel = "Get OTP"
        //                 }

        //                 this.OTPLabel = this.OTPSeconds + "s"
        //             }, 1000)
        //         }
        //     }).catch(({ response }) => {
        //         if (response.status == 422) {
        //             this.errors = response.data.errors
        //         }

        //         if (!response.data.success) {
        //             console.log(response)
        //             this.errors.phone = [response.data.message]
        //         }
        //     })
        // },
        chooseImage() {
            this.$refs.chooseImage.click()
        },
        imageChoosed(e) {
            const image = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
                this.previewImage = e.target.result;
            };
            this.image = e.target.files[0]

        },
        handleRegister() {
            let data = new FormData();
            data.append('image', this.image);
            data.append('phone', this.phone);
            data.append('name', this.name);
            data.append('password', this.password);
            data.append('comfirmpassword', this.comfirmpassword);
            this.$store.dispatch('loading')
            axios.post(this.registerUrl, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((res) => {
                this.$store.dispatch('loadingComplete')
                localStorage.setItem('userphone', this.phone);
                localStorage.setItem('userpassword', this.password);
                if (res.status === 200) {
                    this.$router.push({ name: 'home' })
                }
            }).catch((error) => {
                console.log(error);
                this.$store.dispatch('showError', 'Phone is already taken');
                // if (error.status == 422) {
                //     this.$store.dispatch('showError', 'Phone is already taken!')
                //     this.errors = error.data.errors
                //     if (this.errors.phone && this.errors.phone.length > 0 || this.errors.otp && this.errors.otp.length > 0) {
                //         this.step = 1;
                //     }
                // }
            });
        },
    },
}
</script>

<style>
.profile-image {
    width: 100px;
    height: 100px;
    background-color: #EBF0FD;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.get-otp {
    font-weight: 800;
    position: relative;
    top: 7px;
}</style>
