<template>
    <div class="p-4">
        <form @submit="confirm()" class="d-flex flex-column justify-content-between h-100">
            <h1 class="title mb-3">PIN ရိုက်ပါ</h1>

            <p class="fw-400 fs-14">ငွေထုတ်ရန် PIN ရိုက်ထည့်ပါ</p>

            <div>
                <FormGroup v-model="pin" :error="errors.phone" label="PIN" placeholder="Enter Pin" type="number">
                </FormGroup>
            </div>

            <div class="text-end text-primary">
                <span class="fw-400 fs-14">Forgot your PIN?</span>
            </div>

            <PrimaryButton @click="withdraw()" class="login-btn mt-auto">Confirm</PrimaryButton>
        </form>
    </div>
</template>
<script>
export default {
    data() {
        return {
            pin: "",
            errors: {

            }
        }
    },
    mounted() {

    },
    methods: {
        withdraw() {
            this.$router.push({
                name: "transaction-pending",
                params: {
                    type: "withdraw"
                }
            })
        }
    }
}
</script>
<style></style>
