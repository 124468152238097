<template>
    <div v-if="agent" class="p-4 d-flex flex-column" style="height: 94vh;">
        <div class="d-flex align-items-center">
            <img :src="selectedPaymentInfo.image" style="width: 50px;" alt="">
            <h1 class="mb-0 mx-2 fw-600 fs-18">{{ selectedPaymentInfo.name }}</h1>
            <span class="fw-400 fs-14">နှင့် Unitဝယ်မည်</span>
        </div>
        <div class="bg-thin text-center mt-4 rounded p-3">
            <h1 class="fw-600 fs-16 mb-0">{{ selectedPaymentInfo.receiver_account_phone }}</h1>
            <p class="fw-400 fs-14 mb-2">{{ selectedPaymentInfo.receiver_account_name }}</p>
            <span class="text-primary">
                <font-awesome-icon icon="fa-regular fa-clone" class="me-2" />
                <span class="fw-400 fs-13"
                    @click="$helper.copyToClipBoard(selectedPaymentInfo.receiver_account_phone)">ကူးယူမည်</span>
            </span>
        </div>
        <div class="mt-4">
            <h1 class="fw-600 fs-14">Checkout</h1>
            <div class="row mb-4">
                <div class="col-6">
                    <span class="fw-400 fs-13">ဝယ်ယူမည့်ပမာဏ</span>
                </div>
                <div class="col-6 text-end">
                    <span class="fw-400 fs-14">{{ $helper.formattedNumber(selectedAmount) }}
                        Ks</span>
                </div>
            </div>

            <div class="row mb-4">
                <div class="col-6">
                    <span class="fw-400 fs-13">ဘောက်ချာနံပါတ်</span>
                </div>
                <div class="col-6 text-end">
                    <span class="fw-400 fs-14">12345678</span>
                </div>
            </div>

            <div class="row mb-4">
                <div class="col-6">
                    <span class="fw-400 fs-13">Payအမျိုးအစား</span>
                </div>
                <div class="col-6 text-end">
                    <span class="fw-400 fs-14">{{ selectedPaymentInfo.name }}</span>
                </div>
            </div>

            <div class="mb-2">
                <label class="d-flex fw-400 fs-12 mb-2">
                    <h1 class="mb-0 fw-600 fs-14 me-2">Transaction ID</h1>
                    (နောက်ဆုံးဂဏန်း ၆ လုံး)
                </label>
                <FormGroup v-model="transactionId" placeholder="Enter the last 6 transaction numbers"></FormGroup>
            </div>

            <div class="notice warning">
                <img src="@/assets/notice.svg" class="img-fluid me-2" alt="">
                <p class="mb-0 fw-400 fs-12">
                    လွဲပြီးပါက ID နောက်ဆုံးဂဏန်း၆လုံးဖြည့်သွင်း
                    ပေးရန်လိုအပ်ပါသည်
                </p>
            </div>

            <div class="mt-3 d-flex align-items-center">
                <font-awesome-icon icon="fa-brands fa-youtube" class="me-2 text-primary" size="lg" />
                <span class="fw-400 fs-13 text-decoration-underline">အမှားအယွင်းမရှိစေရန် Unitဝယ်နည်း ကြည့်ပါ</span>
            </div>
        </div>
        <div class="mt-auto row">
            <div class="col-6">
                <SecondaryButton>နောက်သို့</SecondaryButton>
            </div>
            <div class="col-6">
                <PrimaryButton @click="showConfirmationBox = true" :disabled="transactionId == null || transactionId == ''">
                    ငွေလွဲပြီးပါပြီ</PrimaryButton>
            </div>
        </div>
        <ConfirmationBox @cancel="closeModal()" iconColor="text-danger" icon="fa-solid fa-exclamation-circle"
            okButtonText="သေချာသည်" @ok="submit()" cancelButtonText="မသေချာပါ" :show="showConfirmationBox"
            title="Agent ဆီသို့လွဲပြီးတာ
                                                                                                                                                                                                                                                                                                            သေချာပါလား?">
        </ConfirmationBox>
    </div>
</template>

<script>
import ConfirmationBox from '@/components/ConfirmationBoxComponent.vue';
import userService from '@/services/user.service';
export default {
    components: {
        ConfirmationBox
    },
    data() {
        return {
            payment_methods: this.$store.state.cash.paymentMethods,
            selectedPayment: this.$route.params.payment,
            selectedAgent: this.$route.params.agent,
            selectedAmount: this.$store.state.cash.selectedTopUpAmount,
            showConfirmationBox: false,
            transactionId: null,
            agent: null,
        };
    },
    mounted() {
        this.fetchAgentInfo()
    },
    methods: {
        closeModal() {
            this.showConfirmationBox = false
        },
        fetchAgentInfo() {
            this.$store.dispatch('loading')
            userService.getDepositAgentInfo(this.selectedAgent).then(({ data }) => {
                this.$store.dispatch('loadingComplete')
                this.agent = data.data
            })
        },
        submit() {
            let data = {
                'transaction_id': this.transactionId,
                'payment_method': this.selectedPayment,
                'amount': this.selectedAmount,
            }

            this.$store.dispatch('loading')
            userService.submitDeposit(this.selectedAgent, data).then(({ data }) => {
                this.$store.dispatch('loadingComplete')
                if (data.success) {
                    this.$store.dispatch('showToast', 'ယူနစ်လွှဲပြောင်းရန် ပြင်ဆင်နေပါသည်။')
                    this.$router.push({
                        name: 'home'
                    })
                }
            })
        }

    },
    computed: {
        selectedPaymentInfo() {
            return this.agent.payment_methods.find(p => p.id == this.selectedPayment);
        }
    },
}
</script>