import userService from "@/services/user.service";

export const index = {
  state: {
    loading: 0,
    errorMessage: null,
    toastMessage: null,
    homeBannerText: null,
    homeBannerImages: [],
    serviceBannerText: null,
    serviceBannerImages:[]
  },
  actions: {
    loading({ commit }) {
        commit('increaseLoading');
    },
    loadingComplete({ commit }) {
        commit('decreaseLoading');
    },
    showError({ commit }, message) {
      commit('showError',message)
    },
    hideError({ commit }) {
      commit('hideError')
    },
    showToast({ commit }, message) {
      commit('showToast',message)
    },
    hideToast({ commit }) {
      commit('hideToast')
    },
    getHomeBanner({ commit }) {
      userService.getHomeBanner().then((response) => {
        commit('updateHomeBanner', {
            images: response.data.data,
            text:response.data.title
        })
        return Promise.resolve(response);
      })
    },
     getServiceBanner({ commit }) {
      
      userService.getServiceBanner().then((response) => {
        commit('updateServiceBanner', {
            images: response.data.data,
            text:response.data.title
        })
        return Promise.resolve(response);
      })
    }
  },
  mutations: {
    increaseLoading(state) {
        state.loading++;
    },
    decreaseLoading(state) {
        state.loading--;
    },
    showError(state,message) {
      state.errorMessage = message
    },
    hideError(state) {
      state.errorMessage = null
    },
    showToast(state,message) {
      state.toastMessage = message
    },
    hideToast(state) {
      state.toastMessage = null
    },
    updateHomeBanner(state, data) {
      state.homeBannerImages = data.images
      state.homeBannerText = data.text
    },
    updateServiceBanner(state, data) {
      state.serviceBannerImages = data.images
      state.serviceBannerText = data.text
    }
  }
};